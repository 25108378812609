import React, { useState, useEffect } from "react";
import { Redirect, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as AlertState from "../../store/ducks/auth.duck";
import styled from "styled-components";
import DataOutput from "../../components/DataOutput";
import UserInfo from "../../components/UserInfo";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import {
  Portlet,
  PortletHeader,
  PortletBody,
  PortletHeaderToolbar,
} from "../../partials/content/Portlet";
import { Modal, Alert, Dropdown } from "react-bootstrap";
// eslint-disable-next-line no-restricted-imports
import BButton from "react-bootstrap/Button";
import DatePicker from "../../components/DatePicker";
import DiagramOverview from "./OverviewTable/DiagramOverview";
import TableOverview from "./OverviewTable/TableOverview";
import RecentMetricsOverview from "./OverviewTable/RecentMetricsOverview";
import HighPriorityMetric from "./OverviewTable/HighPriorityMetric";
import Circumferences from "./OverviewTable/Circumferences";
import "./table.scss";
import Logout from "../auth/Logout";
import Loader from "../../components/Loader";
import ReorderIcon from "@material-ui/icons/Reorder";
import { Menu, MenuItem, Button } from "@material-ui/core";
import {
  getProgressImg,
  getImg,
  getCheckList,
  getRecentMetric,
  getHighPriorityMetrics,
  getCircumferences,
  getGraphData,
  getActiveMetrics,
  addImg,
  getProfileValid,
  getProtocol,
} from "../../crud/profile.crud";
import moment from "moment";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import DropZone from "../../components/DropZone";
import * as media from "../../crud/media.crud";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import FormatAlignJustifyIcon from "@material-ui/icons/FormatAlignJustify";
import defaultPhoto from "../../../_metronic/_assets/media/default-photo.jpg";

const ImgComponent = ({ src, alt, className, style }) => {
  const [url, setUrl] = useState("");

  useEffect(() => {
    src &&
      media
        .getImg(src)
        .then((res) => setUrl(res))
        .catch((err) => setUrl(err));
  }, [src]);
  return (
    url && <img src={url} alt={alt} className={className} style={style || {}} />
  );
};

const Overview = (props) => {
  document.title = "TrainerMetrics - Overview";

  const id = props.match.params.id;
  const dispatch = useDispatch();
  const [alerts, setAlerts] = useState([]);
  const [metricData, setMetricData] = useState([]);
  const [photoData, setPhotoData] = useState({});
  const [typeVis, setTypeVis] = useState(null);
  const [type, setType] = useState("three_site_skinfold_Jackson_Pollock");
  const [current, setCurrent] = useState(0);
  const [gender, setGender] = useState("");
  const [photo, setPhoto] = useState([]);
  const [circumferences, setCircumferences] = useState([]);
  const [highPriorityMetrics, setHighPriorityMetrics] = useState([]);
  const [date, setDate] = useState([
    "01/01/2020",
    moment(new Date()).format("MM/DD/YYYY"),
  ]);
  const [graficData, setGraficData] = useState({ metrics: [] });
  const [recentMetrics, setRecentMetrics] = useState([]);
  const [recentMetricsLabel, setRecentMetricsLabel] = useState("");
  const [logout, setLogout] = useState(null);
  const [loaderGraphData, setLoaderGraphData] = useState(false);
  const [loaderRecentMetric, setLoaderRecentMetric] = useState(false);
  const [loaderImg, setLoaderImg] = useState(false);
  const [loaderCheckList, setLoaderCheckList] = useState(false);
  const [loaderHighPriorityMetrics, setLoaderHighPriorityMetrics] = useState(
    false
  );
  const [loaderCircumferences, setLoaderCircumferences] = useState(false);
  const [loaderActiveMetrics, setLoaderActiveMetrics] = useState(false);
  const [modal, setModal] = useState(false);
  const [pathPhoto, setPathPhoto] = useState("");
  const [photoDate, setPhotoDate] = useState(moment().format("MM/DD/YYYY"));
  const [errorsPhotoDate, setErrorsPhotoDate] = useState("");
  const [typeOutputMetric, setOutputMetric] = useState(0);
  const [redirect, setRedirect] = useState(false);
  const [protocol, setProtocol] = useState(false);
  const [protocolData, setProtocolData] = useState([]);
  const [loaderProtocol, setLoaderProtocol] = useState(false);
  const [protocolLogout, setProtocolLogout] = useState(null);

  useEffect(() => {
    getProfileValid(id).then((res) => {
      setRedirect(!res.data.is_valid);
      if (!res.data.is_valid) {
        dispatch(
          AlertState.actions.alert({
            text: "Please fill out the required fields below to continue",
            variant: false,
          })
        );
      }
    });
  }, [id]);

  const addPhoto = () => {
    setLoaderImg(true);
    let data = {
      file_data: {
        id: photoData.id,
        name: photoData.file_name || Math.random().toString(),
      },
      test_date: photoDate,
      is_progress: 1,
    };
    addImg(id, data)
      .then((res) => {
        setLoaderImg(false);
        setModal(false);
        loadProgressImg();
        setPathPhoto("");
        dispatch(
          AlertState.actions.alert({
            text: "Photo is added",
            variant: true,
          })
        );
      })
      .catch(({ response }) => {
        response &&
          response.data &&
          dispatch(
            AlertState.actions.alert({
              text: response.data.message || response.data.error,
              variant: false,
            })
          );
        setLoaderImg(false);
        if (response && response.status === 401) {
          setLogout(<Logout />);
        } else if (response && response.status === 403) {
          setLogout(<Redirect to="/profile-list" />);
        }
      });
  };

  useEffect(() => {
    if (
      moment(photoDate, "MM/DD/YYYY").toDate() > new Date() ||
      `${moment(photoDate, "MM/DD/YYYY").toDate()}` === "Invalid Date" ||
      photoDate.indexOf(" ") > -1
    ) {
      setErrorsPhotoDate("Please, insert valid date");
    } else {
      setErrorsPhotoDate("");
    }
  }, [photoDate]);

  useEffect(() => {
    refreshData();
    refreshMetrics();
    // eslint-disable-next-line
  }, [id, type, date]);

  useEffect(() => {
    getFirstDate();
    // eslint-disable-next-line
  }, [type]);

  const getFirstDate = () => {
    setLoaderGraphData(true);
    getGraphData(id, {
      start: "01/01/2000",
      end: moment(new Date()).format("MM/DD/YYYY"),
      type: type,
    })
      .then((res) => {
        setLoaderGraphData(false);
        if (res.data.data.metrics[0]) {
          setDate([
            res.data.data.metrics[0].date,
            moment(new Date()).format("MM/DD/YYYY"),
          ]);
        }
      })
      .catch(() => {
        setLoaderGraphData(false);
      });
  };

  const refreshData = () => {
    setLoaderGraphData(true);
    getGraphData(id, {
      start: date[0],
      end: date[1],
      type: type,
    })
      .then((res) => {
        setLoaderGraphData(false);
        setGraficData(res.data.data);
      })
      .catch(({ response }) => {
        setLoaderGraphData(false);
        if (response && response.status === 401) {
          setLogout(<Logout />);
        } else if (response && response.status === 403) {
          setLogout(<Redirect to="/profile-list" />);
        }
      });
  };

  const refreshMetrics = () => {
    setLoaderRecentMetric(true);
    getRecentMetric(id, type)
      .then((res) => {
        setLoaderRecentMetric(false);
        setRecentMetrics(res.data.data.metrics);
        setRecentMetricsLabel(res.data.data.label);
      })
      .catch(({ response }) => {
        setLoaderRecentMetric(false);
        if (response && response.status === 401) {
          setLogout(<Logout />);
        } else if (response && response.status === 403) {
          setLogout(<Redirect to="/profile-list" />);
        }
      });
  };

  const loadProgressImg = () => {
    setLoaderImg(true);
    getImg(id, true)
      .then((res) => {
        setLoaderImg(false);
        let photos = res.data.data;
        if (photos.length < 2) {
          photos.push(
            { file: { path: defaultPhoto } },
            { file: { path: defaultPhoto } }
          );
        }
        setPhoto(photos);
      })
      .catch(({ response }) => {
        setLoaderImg(false);
        if (response && response.status === 401) {
          setLogout(<Logout />);
        } else if (response && response.status === 403) {
          setLogout(<Redirect to="/profile-list" />);
        }
      });
  };

  const loadProtocol = () => {
    setLoaderProtocol(true);
    getProtocol(type)
      .then((res) => {
        setLoaderProtocol(false);
        setProtocolData(res.data.data);
        setProtocol(true);
      })
      .catch(({ response }) => {
        dispatch(
          AlertState.actions.alert({
            text:
              typeof response.data.error === "string" && response.data.error,
            variant: false,
          })
        );
        setLoaderProtocol(false);
        if (response && response.status === 401) {
          setProtocolLogout(<Logout />);
        } else if (response && response.status === 403) {
          setProtocolLogout(<Redirect to="/profile-list" />);
        }
      });
  };

  useEffect(() => {
    loadProgressImg();

    setLoaderCheckList(true);
    getCheckList(id)
      .then((res) => {
        setLoaderCheckList(false);
        setAlerts(res.data.data);
      })
      .catch(({ response }) => {
        setLoaderCheckList(false);
        if (response && response.status === 401) {
          setLogout(<Logout />);
        } else if (response && response.status === 403) {
          setLogout(<Redirect to="/profile-list" />);
        }
      });

    setLoaderHighPriorityMetrics(true);
    getHighPriorityMetrics(id)
      .then((res) => {
        setLoaderHighPriorityMetrics(false);
        setHighPriorityMetrics(
          res.data.data
            .filter((item) => !!item.date)
            .map((item) => ({ ...item, days: parseInt(item.days) }))
            .sort((a, b) => b.days - a.days)
        );
      })
      .catch(({ response }) => {
        setLoaderHighPriorityMetrics(false);
        if (response && response.status === 401) {
          setLogout(<Logout />);
        } else if (response && response.status === 403) {
          setLogout(<Redirect to="/profile-list" />);
        }
      });

    setLoaderCircumferences(true);
    getCircumferences(id)
      .then((res) => {
        setLoaderCircumferences(false);
        setGender(res.data.data.gender);
        setCircumferences(res.data.data.circumferences);
      })
      .catch(({ response }) => {
        setLoaderCircumferences(false);
        if (response && response.status === 401) {
          setLogout(<Logout />);
        } else if (response && response.status === 403) {
          setLogout(<Redirect to="/profile-list" />);
        }
      });

    setLoaderActiveMetrics(true);
    getActiveMetrics(id, "overview")
      .then((res) => {
        setLoaderActiveMetrics(false);
        setMetricData(res.data.data);
        refreshData();
      })
      .catch(({ response }) => {
        setLoaderActiveMetrics(false);
        if (response && response.status === 401) {
          setLogout(<Logout />);
        } else if (response && response.status === 403) {
          setLogout(<Redirect to="/profile-list" />);
        }
      });
    // eslint-disable-next-line
  }, [id]);

  const getUser = (data) => {};

  const getPdf = () => {
    return `/report/${id}/name/${type}`;
  };

  return (
    <StyledOverview>
      {redirect && <Redirect to={`/profile/${id}/info`} />}

      {logout}
      <Loader
        visible={
          loaderGraphData ||
          loaderRecentMetric ||
          loaderImg ||
          loaderCheckList ||
          loaderHighPriorityMetrics ||
          loaderCircumferences ||
          loaderActiveMetrics
        }
      />

      <div className="row flex-md-row-reverse" style={{ marginBottom: "20px" }}>
        <DataOutput id={id} />
        <UserInfo id={id} getUser={getUser} />
      </div>

      <div className="row row-full-height">
        <div
          className="col-sm-12 col-md-12 col-lg-6"
          style={{ display: "flex", overflow: "hidden" }}
        >
          <Portlet style={{ width: "100%" }}>
            <PortletHeader title="PROGRESS PHOTOS" />
            <PortletBody style={{ height: "100%" }}>
              <div
                className="comparison"
                style={{ height: "100%", width: "100%", overflow: "hidden" }}
              >
                {photo.slice(0, 2).map((item, index) => {
                  return (
                    <div
                      className="comparison-elem"
                      key={item.id}
                      onClick={() => setModal(true)}
                      style={{ minWidth: "48%" }}
                    >
                      <ImgComponent
                        src={item.file.path}
                        alt="img"
                        style={{ objectFit: "contain" }}
                      />
                      <span>{moment(item.test_date).format("MM/DD/YYYY")}</span>
                    </div>
                  );
                })}
                <Modal show={modal} onHide={(e) => setModal(false)}>
                  <Modal.Header closeButton>
                    <Modal.Title>Add Progress Photo</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <DropZone
                      submit={(val) => {
                        setPathPhoto(val.path);
                        setPhotoData(val);
                      }}
                    />
                    <DatePicker
                      value={photoDate}
                      format={"MM/DD/YYYY"}
                      onChange={(value) => {
                        setPhotoDate(value, 0);
                      }}
                      helperText={errorsPhotoDate}
                      error={Boolean(errorsPhotoDate)}
                    />
                  </Modal.Body>
                  <Modal.Footer>
                    <BButton
                      variant="secondary"
                      onClick={(e) => {
                        setModal(false);
                      }}
                    >
                      Close
                    </BButton>
                    <BButton
                      variant="primary"
                      className="btn-blue"
                      onClick={(e) => addPhoto()}
                      disabled={pathPhoto === "" || errorsPhotoDate !== ""}
                    >
                      Save photo
                    </BButton>
                  </Modal.Footer>
                </Modal>
              </div>
            </PortletBody>
          </Portlet>
        </div>
        <div
          className="col-sm-12 col-md-12 col-lg-6"
          style={{ display: "flex" }}
        >
          <Portlet>
            <PortletHeader title="PROFILE CHECKLIST" />
            <PortletBody>
              {alerts.map((item, index) => {
                return (
                  <Alert
                    variant={item.value ? "success" : "danger"}
                    key={`alert-${index}`}
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>{item.title}</div>
                    <div>{item.value ? <CheckIcon /> : <CloseIcon />}</div>
                  </Alert>
                );
              })}
            </PortletBody>
          </Portlet>
        </div>
      </div>

      <div className="row row-full-height">
        <div className="col-lg-12">
          <Portlet>
            <PortletHeader
              title={
                metricData[current] && metricData[current].title
                  ? metricData[current].title
                  : ""
              }
              toolbar={
                <PortletHeaderToolbar>
                  <div className="toolbarm">
                    <div
                      className={`toolbarm-elem ${
                        typeOutputMetric === 0 ? "active" : ""
                      }`}
                      onClick={() => setOutputMetric(0)}
                    >
                      <EqualizerIcon />
                    </div>
                    <div
                      className={`toolbarm-elem ${
                        typeOutputMetric === 1 ? "active" : ""
                      }`}
                      onClick={() => setOutputMetric(1)}
                    >
                      <FormatAlignJustifyIcon />
                    </div>
                    {metricData.length > 2 && (
                      <Dropdown className="overview-menu-dropdown" drop="left">
                        <Dropdown.Toggle>
                          <MoreHorizIcon />
                        </Dropdown.Toggle>
                        <Dropdown.Menu flip>
                          {metricData.map((item, index) => {
                            return (
                              <Dropdown.Item
                                key={index}
                                onClick={() => {
                                  setCurrent(index);
                                  setTypeVis(null);
                                  setOutputMetric(0);
                                }}
                              >
                                {item.list_title}
                              </Dropdown.Item>
                            );
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                  </div>
                  {metricData.length > 2 ? (
                    <div
                      className="selectd-wrap"
                      onMouseLeave={() => {
                        setTypeVis(null);
                      }}
                    >
                      <Button
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={(e) => setTypeVis(e.currentTarget)}
                      >
                        <ReorderIcon
                          onClick={() => {
                            setTypeVis(!typeVis);
                          }}
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                        />
                      </Button>
                      <Menu
                        id="simple-menu"
                        anchorEl={typeVis}
                        keepMounted
                        open={!!typeVis}
                        onClose={() => setTypeVis(null)}
                      >
                        {metricData.map((item, index) => {
                          return (
                            <MenuItem
                              key={index}
                              onClick={() => {
                                setCurrent(index);
                                setTypeVis(null);
                                setOutputMetric(0);
                              }}
                            >
                              {item.list_title}
                            </MenuItem>
                          );
                        })}
                      </Menu>
                    </div>
                  ) : null}
                </PortletHeaderToolbar>
              }
            />

            <PortletBody>
              {typeOutputMetric === 0 ? (
                <DiagramOverview
                  id={id}
                  dataMetric={metricData[current]}
                  current={current}
                  type={type}
                  setType={setType}
                  date={date}
                  setDate={setDate}
                  data={graficData}
                  protocolLogout={protocolLogout}
                  loadProtocol={loadProtocol}
                  loaderProtocol={loaderProtocol}
                  protocolData={protocolData}
                  protocol={protocol}
                  setProtocol={setProtocol}
                  getPdf={getPdf}
                  loadData={() => {
                    refreshData();
                    refreshMetrics();
                  }}
                  setOutputMetric={setOutputMetric}
                />
              ) : typeOutputMetric === 1 ? (
                <TableOverview
                  id={id}
                  dataMetric={metricData[current]}
                  current={current}
                  type={type}
                  setType={setType}
                  date={date}
                  setDate={setDate}
                  data={graficData}
                  protocolLogout={protocolLogout}
                  loadProtocol={loadProtocol}
                  loaderProtocol={loaderProtocol}
                  protocolData={protocolData}
                  protocol={protocol}
                  setProtocol={setProtocol}
                  getPdf={getPdf}
                  loadData={() => {
                    refreshData();
                    refreshMetrics();
                  }}
                  typeOutputMetric={typeOutputMetric}
                />
              ) : null}
            </PortletBody>
          </Portlet>
        </div>
      </div>

      <div className="row row-full-height">
        <div className="col-lg-12">
          <Portlet className="portlet">
            <PortletHeader title="RECENT METRICS" />
            <PortletBody className="settingtable">
              <div className="table-container">
                <RecentMetricsOverview
                  data={recentMetrics}
                  label1={recentMetricsLabel}
                  label2={
                    metricData[current] && metricData[current].label
                      ? metricData[current].label
                      : ""
                  }
                />
              </div>
            </PortletBody>
          </Portlet>
        </div>
      </div>

      <div className="row row-full-height">
        <div className="col-sm-12 col-md-12 col-lg-6">
          <Portlet>
            <PortletHeader title="HIGH PRIORITY METRICS" />
            <PortletBody className="settingtable">
              <div className="table-container">
                <HighPriorityMetric data={highPriorityMetrics} />
              </div>
            </PortletBody>
          </Portlet>
        </div>

        <div className="col-sm-12 col-md-12 col-lg-6">
          <Portlet>
            <PortletHeader title="CIRCUMFERENCES" />
            <PortletBody>
              <Circumferences circumferences={circumferences} gender={gender} />
            </PortletBody>
          </Portlet>
        </div>
      </div>
    </StyledOverview>
  );
};

const StyledOverview = styled.div`
  .MuiSelect-select {
    min-width: 150px;
  }

  .comparison {
    display: flex;

    &-elem {
      display: flex;
      width: 50%;
      flex-direction: column;
      border-radius: 4px;
      padding: 4px;
      text-align: center;
      justify-content: space-between;
      background-color: #f8f8f8;
      margin: 10px;

      img {
        border-radius: 4px;
        object-fit: cover;
        width: 100%;
        height: calc(100% - 24px);
        margin-bottom: 10px;
      }
    }
  }

  .toolbarm {
    display: flex;
    align-items: center;

    &-elem {
      border-radius: 4px;
      padding: 3px;
      height: 25px;
      width: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &.active {
        background: #e5e9f0;
      }
    }
  }

  .overview-menu-dropdown {
    left: 8px;
    &.show {
      .dropdown-toggle {
        background-color: #e5e9f0 !important;
      }
    }

    .dropdown-toggle {
      transform: rotate(90deg);
      padding: 3px;
      background-color: unset !important;

      &::after,
      &::before {
        content: none;
      }
      .MuiSvgIcon-root {
        fill: #646c9a !important;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .kt-portlet__body.settingtable {
      padding-top: 0;
      overflow: hidden;
    }
    .table-container {
      overflow: scroll;
      max-height: 700px;
    }
    .selectd-wrap {
      display: none;
    }
  }

  @media screen and (min-width: 768px) {
    .overview-menu-dropdown {
      display: none;
    }
  }
`;

export default Overview;
