import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as AlertState from "../../../../store/ducks/auth.duck";
import { Redirect } from "react-router-dom";
import Loader from "../../../../components/Loader";
import styled from "styled-components";
import { Pagination } from "react-bootstrap";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
  ListItemText,
  OutlinedInput,
  Avatar,
  Typography,
} from "@material-ui/core";

import TableList from "../../../../components/TableList";

import { LeadsSummary } from "./components/LeadsSummary/LeadsSummary";

import { useUsers } from "../hooks/useUsers";
import { useGroups } from "../hooks/useGroups";
import { useLeads } from "../hooks/useLeads";

import { PencilEditIcon } from "../../../../../assets";
import {
  EditFieldModal,
  FIELDS_TYPE,
} from "../modals/EditFieldModal/EditFieldModal";

import "../../table.scss";
import "../../style.scss";

import { updatLeadsNote } from "../../../../crud/leads.crud";
import { renderTextWithTooltip } from "../helpers/renderTextWithTooltip";
import { NOTES_TABLE_TYPE } from "../constants/constants";
import { ImgComponent } from "../helpers/ImgComponent";
import moment from "moment";
import { priceToView } from "../../../../helpers/thousandSeparator";
import { renderNumericValue } from "../helpers/renderNumericValue";
import { Link } from "react-router-dom";

const initSetting = {};

export const LeadsList = (props) => {
  document.title = "TrainerMetrics - Leads";

  const plan = useSelector((state) => state.user_info.subscription?.plan?.name);
  const role = useSelector((state) => state.auth?.user?.roles);
  const dispatch = useDispatch();

  const {
    loading,
    error,
    data,
    search,
    setSearch,
    sort,
    setSort,
    staff,
    setStaff,
    groups,
    setGroups,

    year,
    setYear,
    month,
    setMonth,
    pagination,
    perPage,
    setPage,
    setPerPage,
    totals,
    date,
    setData,
    refetchTotals,
  } = useLeads(initSetting);

  const { userList, loading: loaderUser } = useUsers({
    groups: groups.filter(Boolean),
  });
  const { groupList, loading: loaderAllGroups } = useGroups();

  const [logout, setLogout] = useState(null);

  const [clickedRow, setClikedRow] = useState(null);
  const [openModal, setOpenModal] = useState(null);

  const [fetching, setFetching] = useState(false);

  //redirect if no have access
  useEffect(() => {
    if (role === "staff" || role === "client") {
      dispatch(
        AlertState.actions.alert({
          text: "User does not have the right roles",
          variant: false,
        })
      );
      setLogout(<Redirect to="/profile-list" />);
    }
  }, [role]);

  useEffect(() => {
    if (error) {
      dispatch(
        AlertState.actions.alert({
          text: "Something went wrong",
          variant: false,
        })
      );
    }
  }, [error]);

  const handleClickOnRow = (value, name, record, title, type) => {
    setClikedRow({ value, name, record, title, type });
    setOpenModal(true);
  };

  const handleClose = () => {
    setClikedRow(null);
    setOpenModal(false);
  };

  const handleUpdateField = async (value) => {
    const profileId = clickedRow.record.profile.id;
    const staffId = clickedRow.record?.staff?.id;

    try {
      setFetching(true);

      const req = {
        ...date,
        text: value,
        user_id: staffId,
        type: NOTES_TABLE_TYPE.LEADS,
      };
      await updatLeadsNote(profileId, req);

      const updateData = data.map((item) =>
        item.values.profile.id === profileId &&
        (!staffId || item.values.staff.id === staffId)
          ? { values: { ...item.values, [clickedRow.name]: value } }
          : item
      );

      setData(updateData);
      refetchTotals();
      handleClose();
      dispatch(
        AlertState.actions.alert({
          text: "Leads info is updated",
          variant: true,
        })
      );
    } catch (error) {
      console.dir(error);
      dispatch(
        AlertState.actions.alert({
          text: error?.message || "Something went wrong!",
          variant: false,
        })
      );
    } finally {
      setFetching(false);
    }
  };

  const header = [
    {
      name: "staff_names",
      title: "Staff",
      render: (value) => renderTextWithTooltip(value || "--"),
    },
    {
      name: "profile",
      title: "Profile",
      render: (profile, name, record) => {
        return (
          <NameWrapper>
            {profile?.picture ? (
              <ImgComponent
                src={profile.picture}
                alt="img"
                className="profile-photo"
              />
            ) : (
              <Avatar className="profile-photo">{`${profile?.first_name[0]}${profile?.last_name[0]}`}</Avatar>
            )}
            <p>
              <Link to={`/profile/${profile.id}/overview`}>
                {profile?.first_name} {profile?.last_name}
              </Link>
            </p>
          </NameWrapper>
        );
      },
    },

    {
      name: "count_checkins",
      title: (
        <>
          <p style={{ margin: 0 }}>Last 30-Day </p>
          <p style={{ margin: 0 }}>Check-Ins</p>
        </>
      ),
      render: renderNumericValue,
    },

    {
      name: "scheduled_date",
      title: "Scheduled date",
      render: (value) => (value ? moment(value).format("MM/DD/YYYY") : "--"),
    },
    {
      name: "attended_date",
      title: "Attended Date",
      render: (value) => (value ? moment(value).format("MM/DD/YYYY") : "--"),
    },
    {
      name: "purchase_date",
      title: "Purchased Date",
      render: (value) => (value ? moment(value).format("MM/DD/YYYY") : "--"),
    },
    {
      name: "product_name",
      title: "Product Name",
      render: (value) => renderTextWithTooltip(value || "--"),
    },
    {
      name: "exp_package_amount",
      title: "Amount",
      render: (value) => priceToView(value),
    },
    {
      name: "lead_note",
      title: "Lead Note",
      render: (value, name, record) => (
        <EditableCol>
          {renderTextWithTooltip(value || "--")}

          <span
            className="pencil-icon"
            onClick={() =>
              handleClickOnRow(
                value,
                name,
                record,
                "Lead Note",
                FIELDS_TYPE.AREA
              )
            }
          >
            <PencilEditIcon />
          </span>
        </EditableCol>
      ),
    },
  ];

  return (
    <StyledGroupLists className="nowrap">
      {logout}
      <Loader visible={loaderAllGroups || loaderUser || loading} />

      <LeadsSummary loading={loading} items={totals} />

      <Portlet>
        <PortletBody>
          <FiltersBlock>
            <div className="btn-toolbar__group">
              <FormControl
                disabled={!groups?.length}
                variant="outlined"
                className="formControl"
              >
                <InputLabel>Select Groups</InputLabel>
                <Select
                  multiple
                  name="group"
                  disabled={!groups?.length}
                  onChange={(e, child) => {
                    if (!child.props.value) {
                      setGroups([0]);
                    } else {
                      setGroups(e.target.value.filter(Boolean));
                    }
                    setPage(1);
                    setStaff([]);
                  }}
                  value={groups}
                  input={<OutlinedInput id={`select-multiple-checkbox`} />}
                  renderValue={(selected) => (
                    <SelectedBox>
                      <span>
                        {selected
                          .map(
                            (item) =>
                              [
                                { name: "(All Groups)", id: 0 },
                                ...groupList,
                              ].find((elem) => elem.id === item).name
                          )
                          .join(", ")}
                      </span>
                    </SelectedBox>
                  )}
                  inputProps={{
                    name: "groups",
                  }}
                  MenuProps={{
                    autoFocus: false,
                    PaperProps: {
                      style: {
                        maxHeight: 250,
                        width: 250,
                      },
                    },
                  }}
                >
                  {[{ name: "(All Groups)", id: 0 }, ...groupList].map(
                    (item) => (
                      <MenuItem key={item.id} value={item.id}>
                        <ListItemText primary={item.name} />
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
              <FormControl variant="outlined" className="formControl">
                <InputLabel>Select Users</InputLabel>
                <Select
                  multiple
                  value={staff}
                  name="staff"
                  onChange={(e, child) => {
                    if (!child.props.value) {
                      setStaff([0]);
                    } else {
                      setStaff(e.target.value.filter(Boolean));
                    }
                    setPage(1);
                  }}
                  input={<OutlinedInput id={`select-multiple-checkbox`} />}
                  renderValue={(selected) =>
                    selected
                      .map(
                        (item) =>
                          [
                            { first_name: "(All Users)", id: 0 },
                            ...userList,
                          ]?.find((elem) => elem.id === item).first_name
                      )
                      .join(", ")
                  }
                  inputProps={{
                    name: "staff",
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 250,
                        width: 250,
                      },
                    },
                  }}
                >
                  {[{ first_name: "(All Users)", id: 0 }, ...userList].map(
                    (item) => (
                      <MenuItem key={item.id} value={item.id}>
                        <ListItemText
                          primary={`${item.first_name} ${item.last_name || ""}`}
                        />
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </div>

            <div
              className={`search-wrap`}
              style={{ display: "flex", alignItems: "center", gap: "4px" }}
            >
              <div className="btn-toolbar__group">
                <FormControl variant="outlined" style={{ width: "100%" }}>
                  <InputLabel htmlFor="month">Month</InputLabel>
                  <Select
                    value={month}
                    onChange={(e) => setMonth(e.target.value)}
                    input={
                      <OutlinedInput labelWidth={42} name="month" id="month" />
                    }
                  >
                    <MenuItem value={"01"}>January</MenuItem>
                    <MenuItem value={"02"}>February</MenuItem>
                    <MenuItem value={"03"}>March</MenuItem>
                    <MenuItem value={"04"}>April</MenuItem>
                    <MenuItem value={"05"}>May</MenuItem>
                    <MenuItem value={"06"}>June</MenuItem>
                    <MenuItem value={"07"}>July</MenuItem>
                    <MenuItem value={"08"}>August</MenuItem>
                    <MenuItem value={"09"}>September</MenuItem>
                    <MenuItem value={"10"}>October</MenuItem>
                    <MenuItem value={"11"}>November</MenuItem>
                    <MenuItem value={"12"}>December</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="btn-toolbar__group">
                <FormControl variant="outlined" style={{ width: "100%" }}>
                  <InputLabel htmlFor="year">Year</InputLabel>
                  <Select
                    value={year}
                    onChange={(e) => setYear(e.target.value)}
                    input={
                      <OutlinedInput labelWidth={30} name="year" id="year" />
                    }
                  >
                    {[...new Array(6)].map((item, index) => (
                      <MenuItem value={index + 2020} key={index}>
                        {2020 + index}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <TextField
                key={"search"}
                className="formControl"
                label="Search"
                margin="normal"
                variant="outlined"
                value={search}
                style={{ marginLeft: 0 }}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    setSearch(e.target.value);
                  }
                }}
              />
            </div>
          </FiltersBlock>
          <TableList
            header={header}
            data={data}
            check={[]}
            sort={sort}
            setSort={(newSort) => {
              if (!sort) {
                return setSort(newSort);
              }
              if (sort[0] !== newSort[0]) {
                return setSort([newSort[0], "asc"]);
              }

              if (sort[1] === "desc") {
                return setSort([undefined, undefined]);
              }
              setSort(newSort);
            }}
            plan={plan}
          />
          {!!pagination?.total && (
            <div className="pagination-wrap">
              <Pagination size="lg">
                <Pagination.First
                  disabled={pagination.current_page === 1}
                  onClick={() => setPage(1)}
                />
                <Pagination.Prev
                  disabled={pagination.current_page === 1}
                  onClick={() => setPage((prev) => prev - 1)}
                />

                <Pagination.Item>{pagination.current_page}</Pagination.Item>

                <Pagination.Next
                  disabled={pagination.last_page === pagination.current_page}
                  onClick={() => setPage((prev) => prev + 1)}
                />
                <Pagination.Last
                  disabled={pagination.last_page === pagination.current_page}
                  onClick={() => setPage(pagination.last_page)}
                />
              </Pagination>

              <div className="pagination-show">
                <FormControl variant="outlined" className="formControl">
                  <InputLabel>Show Entries</InputLabel>
                  <Select
                    value={perPage}
                    input={<OutlinedInput />}
                    onChange={(e) => {
                      setPerPage(e.target.value);
                    }}
                  >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                  </Select>
                </FormControl>
                <Typography variant="body1" gutterBottom>
                  {`Showing ${pagination.from} - ${pagination.to} of ${pagination.total}`}
                </Typography>
              </div>
            </div>
          )}
        </PortletBody>
      </Portlet>
      {openModal && (
        <EditFieldModal
          title={clickedRow?.title}
          value={clickedRow?.value}
          type={clickedRow?.type}
          open={openModal}
          onClose={handleClose}
          onSave={handleUpdateField}
          loading={fetching}
        />
      )}
    </StyledGroupLists>
  );
};

const StyledGroupLists = styled.div`
  .MuiTab-wrapper {
    font-size: 12px;
    font-weight: 700;
  }

  .btn {
    margin-right: 15px;
  }

  .btn-toolbar {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;

    &__group {
      display: flex;
      align-items: center;

      .formControl {
        margin-left: 0;
      }
    }
  }

  .edit-all {
    height: 100%;
    border-radius: 4px;
    background-color: #eef1ff;
    padding: 0 12px;
    font-weight: 600;
    font-size: 13px;
    line-height: 19px;
    color: #5d78ff;
    display: flex;
    align-items: center;
  }

  .pagination {
    margin: 0;

    @media (max-width: 600px) {
      margin: 10px auto;
    }

    &-wrap {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 10px;

      .MuiSelect-select {
        padding: 7px;
      }
    }

    &-show {
      display: flex;
      align-items: center;
      min-width: 290px;
    }
  }

  .formControl {
    width: 150px;
    margin: 5px 10px;
  }
`;

const TabsBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FiltersBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px 0;
`;

const EditableCol = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  & .pencil-icon {
    cursor: pointer;
  }
`;

const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  & p {
    margin-bottom: 0;
    white-space: nowrap;
  }
`;

const SelectedBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  & .close-icon {
    margin: 0;
    color: #646c9a;
    padding: 2px;
    position: absolute;
    right: -6px;
    &:hover {
      z-index: 99999999;
    }
  }
`;
