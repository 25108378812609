import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { useIsMount } from '../../../../hooks/useIsMount';
import * as AlertState from '../../../../../app/store/ducks/auth.duck';
import { Button } from 'react-bootstrap';
import { TextField } from '@material-ui/core';
import DropdownMultiselect from '../../../../components/DropdownMultiselectFetchOptions';
import { Table } from './Table/Table';
import Loader from '../../../../components/Loader';
import { deletePrograms, fetchPrograms } from '../../../../crud/library.crud';
import Pagination from '../../../../components/Pagination';
import { getAllUsersWithoutFiltering } from '../../../../crud/info.crud';
import ConfirmModal from '../../../../components/ConfirmationModal';
import { Tab, Tabs } from '@material-ui/core';
import catchErrorMessage from '../../../../helpers/errorCatcher';
import './list.scss';

export const ProgramsList = () => {
  const history = useHistory();
  const isFirstRender = useIsMount();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [search, setSearch] = useState('');
  const [data, setData] = useState([]);
  const [show, setShow] = useState(10);
  const [page, setPage] = useState(false);
  const [pagination, setPagination] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [check, setCheck] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [sort, setSort] = useState([null, null]);
  const [tab, setTab] = useState(0);
  const userId = useSelector(state => state.auth.user.id);

  const loadData = () => {
    setLoader(true);
    fetchPrograms({
      search,
      page: pagination,
      perPage: show,
      creator_ids: tab === 1 ? [userId] : selectedUsers.map((elem) => elem.id),
      sort: sort[0],
      sortBy: sort[1],
    })
      .then((res) => {
        setData(res.data.programs);
        setLastPage(res.data.last_page);
        setPage({
          current_page: res.data.current_page,
          last_page: res.data.last_page,
          per_page: res.data.per_page,
          total: res.data.total,
        });
      })
      .finally(() => setLoader(false));
  };

  useEffect(() => {
    loadData();
  }, [pagination, show, sort, selectedUsers, tab]);

  useEffect(() => {
    if (isFirstRender) return;
    const handler = setTimeout(() => {
      setLoader(true);
      fetchPrograms({
        search,
        page: 1,
        perPage: show,
        creator_ids:
          tab === 1 ? [userId] : selectedUsers.map((elem) => elem.id),
        sort: sort[0],
        sortBy: sort[1],
      })
        .then((res) => {
          setPagination(1);
          setPage({
            current_page: res.data.current_page,
            last_page: res.data.last_page,
            per_page: res.data.per_page,
            total: res.data.total,
          });
          setData(res.data.programs);
          setLastPage(res.data.last_page);
        })
        .finally(() => setLoader(false));
    }, 1000);
    return () => clearTimeout(handler);
  }, [search]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const fetchUsersForOptions = (search) => {
    setLoader(true);
    getAllUsersWithoutFiltering({ search, disable_filtering: 1 })
      .then((res) => {
        const list = res.data?.data.map((elem) => ({
          ...elem,
          title: elem.name,
        }));
        const sorted = list.sort((a, b) => {
          let first = a.title.toLowerCase();
          let second = b.title.toLowerCase();
          if (first < second) {
            return -1;
          }
          if (first > second) {
            return 1;
          }
          return 0;
        });
        setUsers(sorted);
      })
      .catch((err) => {
        let errText = catchErrorMessage(err);
        dispatch(
          AlertState.actions.alert({
            text: errText,
            variant: false,
          })
        );
      })
      .finally(() => setLoader(false));
  };

  const handleDelete = () => {
    setLoader(true);
    deletePrograms(check.map((elem) => elem.id))
      .then(() => {
        loadData();
        setDeleteModal(false);
        dispatch(
          AlertState.actions.alert({
            text:
              check.length > 1
                ? 'Program deleted successfully'
                : 'Programs deleted successfully',
            variant: true,
          })
        );
        setCheck([]);
      })
      .catch((err) => {
        setDeleteModal(false);
        let errText = catchErrorMessage(err);
        dispatch(
          AlertState.actions.alert({
            text: errText,
            variant: false,
          })
        );
      })
      .finally(() => setLoader(false));
  };

  return (
    <div className="programs-list">
      <Loader visible={loader} />

      <Tabs
        value={tab}
        onChange={(e, value) => setTab(value)}
        indicatorColor="primary"
        textColor="primary"
        scrollButtons="auto"
        variant="scrollable"
      >
        <Tab
          label="ALL PROGRAMS"
          style={{ fontSize: '12px', fontWeight: '700' }}
        />
        <Tab
          label="MY PROGRAMS"
          style={{ fontSize: '12px', fontWeight: '700' }}
        />
      </Tabs>

      <ConfirmModal
        modal={deleteModal}
        setModal={setDeleteModal}
        handleConfirm={handleDelete}
        title="Are you sure you want to delete selected programs?"
        submitText="Yes"
        isCancelButton={true}
      />

      <div className="programs-list__btn-container">
        <Button
          variant="primary"
          className="btn-blue"
          onClick={() => history.push('/library/programs/create')}
        >
          Create Program
        </Button>
      </div>
      <div className="programs-list__sort-container">
        {tab === 0 ? (
          <div className="programs-list__dropdownmultiselect">
            <DropdownMultiselect
              fetchOptions={fetchUsersForOptions}
              data={selectedUsers}
              setData={setSelectedUsers}
              options={users}
              label="Created by"
            />
          </div>
        ) : (
          <div />
        )}

        <TextField
          variant="outlined"
          label="Search"
          value={search}
          onChange={handleSearch}
        />
      </div>

      <div className="d-flex" style={{ marginTop: 15 }}>
        {check.length === 1 && (
          <>
            <Link to={`/program/${check[0].id}`} target="_blank">
              <Button variant="secondary" style={{ height: '100%' }}>
                Print
              </Button>
            </Link>

            <Button
              variant="secondary"
              onClick={() => {
                if (check[0].is_editable) {
                  history.push(`/library/programs/${check[0].id}`);
                } else {
                  dispatch(
                    AlertState.actions.alert({
                      text: 'You do not have permission to edit this program',
                      variant: false,
                    })
                  );
                }
              }}
              style={{ margin: '0 0 0 10px' }}
            >
              Edit
            </Button>

            <Button
                variant="secondary"
                onClick={() => {
                    history.push({
                        pathname: `/library/programs/create`,
                        state: {
                            programId: check[0].id,
                            isCloneForLibrary: true,
                        }
                    })
                }}
                style={{ margin: '0 0 0 10px' }}
            >Duplicate</Button>            
          </>
        )}

        {check.length > 0 && (
          <Button variant="secondary" onClick={() => setDeleteModal(true)} style={{ margin: '0 0 0 10px' }}>
            Delete
          </Button>
        )}
      </div>

      <Table
        data={data}
        check={check}
        setCheck={setCheck}
        sort={sort}
        setSort={setSort}
      />

      <Pagination
        show={show}
        setShow={setShow}
        page={page}
        setPage={setPage}
        pagination={pagination}
        setPagination={setPagination}
        lastPage={lastPage}
        data={data}
        paginationSize="lg"
      />
    </div >
  );
};
