/* eslint-disable no-restricted-imports */
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  OutlinedInput,
  Checkbox,
} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { Pagination, Button } from "react-bootstrap";
import ArrowDown from "@material-ui/icons/ArrowDropDown";
import moment from "moment";
import { getNotes, deleteNotes } from "../../../crud/notes.crud";
import NotesModal from "./NotesModal";
import Loader from "../../../components/Loader";
import DeleteModal from "./DeleteModal";
import { useDispatch } from "react-redux";
import * as AlertState from "../../../store/ducks/auth.duck";
import { withStyles } from "@material-ui/core/styles";
import { InfoModal } from "./InfoModal";

export default ({ id }) => {
  const dispatch = useDispatch();
  const inputLabel = useRef();
  const [labelWidth, setLabelWidth] = useState(0);
  useEffect(() => {
    inputLabel.current && setLabelWidth(inputLabel.current.offsetWidth);
  }, [inputLabel]);

  const [loader, setLoader] = useState(false);
  const [loaderModal, setLoaderModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [data, setData] = useState([]);
  const [meta, setMetaState] = useState({
    current_page: 1,
    from: 1,
    last_page: 1,
    per_page: 10,
    to: 0,
    total: 0,
    sort: "note_date",
    dir: "desc",
  });
  const [check, setCheck] = useState([]);
  const [currentItem, setCurrentItem] = useState({});
  const [openInfoModal, setOpenInfoModal] = useState(false);

  const defRow = [
    {
      label: "Date",
      value: "note_date",
      left: true,
    },
    {
      label: "Category",
      value: "metrics.category",
      left: true,
    },
    {
      label: "Metric / Exercise",
      value: "metrics.metric",
      left: true,
    },
    {
      label: "Notes",
      value: "note_text",
      left: true,
    },
    {
      label: "User",
      value: "created_by",
      left: true,
    },
  ];

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [meta]);

  useEffect(() => {
    let item = data.find((elem) => elem.id === check[0]);
    setCurrentItem(item);
  }, [check, data]);

  const fetchData = () => {
    setLoader(true);
    getNotes({
      id: id,
      ...meta,
    })
      .then((res) => {
        setCheck([]);
        setData(res.data.data);
        setLoader(false);
        if (
          JSON.stringify(meta) !== JSON.stringify({ ...meta, ...res.data.meta })
        )
          setMeta(res.data.meta);
        if (res.data.meta.last_page < res.data.meta.current_page) {
          setMeta({ current_page: res.data.meta.last_page });
        }
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const handleDelete = () => {
    setLoader(true);
    deleteNotes(id, check)
      .then((res) => {
        setCheck([]);
        fetchData();
        setLoader(false);
        setIsDeleteModal(false);
        if (check.length > 1) {
          dispatch(
            AlertState.actions.alert({
              text: "Notes are deleted",
              variant: true,
            })
          );
        } else {
          dispatch(
            AlertState.actions.alert({ text: "Note is deleted", variant: true })
          );
        }
      })
      .catch(({ response }) => {
        setLoader(false);
        if (response.status === 422) {
          setIsDeleteModal(false);
          setOpenInfoModal(true);
          setCheck([]);
          fetchData();
        }
      });
  };

  const setMeta = (obj) => {
    setMetaState({ ...meta, ...obj });
  };

  const sortEdit = (item) => {
    setMeta({
      sort: item.value,
      dir: meta.sort === item.value && meta.dir === "desc" ? "asc" : "desc",
    });
  };

  const chbAll = () => {
    if (check.length === data.length) {
      setCheck([]);
    } else {
      setCheck(data.map((item) => item.id));
    }
  };

  const handleClose = () => setOpenInfoModal(false);

  const handleUpdate = () => {
    getNotes({
      id: id,
      ...meta,
    }).then((res) => {
      if (res.data.data.find((elem) => elem.id === check[0])) {
        setUpdateModal(true);
      } else {
        fetchData();
        setOpenInfoModal(true);
      }
    });
  };

  return (
    <StyledJournal>
      <Loader visible={loader || loaderModal} />

      <InfoModal openInfoModal={openInfoModal} handleClose={handleClose} />

      <DeleteModal
        isDeleteModal={isDeleteModal}
        setIsDeleteModal={setIsDeleteModal}
        handleDelete={handleDelete}
      />

      <div className="settingtable">
        <div className="journal-btn">
          <div className="journal-btns-container">
            {check.length !== 0 ? (
              check.length === 1 ? (
                <div>
                  <Button
                    variant="info"
                    style={{ margin: "0 5px" }}
                    onClick={handleUpdate}
                  >
                    Update
                  </Button>
                  <Button
                    variant="info"
                    style={{ margin: "0 5px" }}
                    onClick={() => setIsDeleteModal(true)}
                  >
                    Delete
                  </Button>
                </div>
              ) : (
                <Button
                  variant="info"
                  style={{ margin: "0 5px" }}
                  onClick={() => setIsDeleteModal(true)}
                >
                  Delete
                </Button>
              )
            ) : null}
          </div>
          <NotesModal
            AlertState={AlertState}
            dispatch={dispatch}
            id={id}
            noteId={currentItem?.id || check[0]}
            clearCheck={() => setCheck([])}
            updateModal={updateModal}
            setUpdateModal={setUpdateModal}
            loader={loaderModal}
            setLoader={setLoaderModal}
            fetchData={fetchData}
            setOpenInfoModal={setOpenInfoModal}
            currentItem={currentItem}
            setCurrentItem={setCurrentItem}
            check={check}
          />
        </div>
        <div className="table-container">
          <table style={{ textAlignLast: "left" }}>
            <thead>
              <tr>
                <th>
                  <Checkbox
                    checked={data.length && check.length === data.length}
                    onChange={chbAll}
                    color="primary"
                  />
                </th>

                {defRow.map((item, index) => {
                  return (
                    <th
                      key={index}
                      onClick={() => sortEdit(item)}
                      className={`${item.left ? "left-content" : ""}${
                        meta.sort === item.value ? " active" : ""
                      }`}
                    >
                      {item.label}
                      <ArrowDown
                        className={`sort-arrow${
                          meta.sort === item.value ? " active" : ""
                        }${
                          meta.sort === item.value && meta.dir === "desc"
                            ? " reverse"
                            : ""
                        }`}
                      />
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => {
                return (
                  <TableRow
                    item={item}
                    key={index}
                    check={check}
                    setCheck={setCheck}
                    setCurrentItem={setCurrentItem}
                    setUpdateModal={setUpdateModal}
                  />
                );
              })}
            </tbody>
          </table>
        </div>

        {data.length === 0 ? (
          <div
            style={{
              margin: "20px auto",
              textAlign: "center",
            }}
          >
            You do not have saved notes. Create your note now
          </div>
        ) : null}
      </div>
      <div className="pagination-wrap">
        <Pagination size="lg">
          <Pagination.First
            onClick={() => setMeta({ ...meta, current_page: 1 })}
            disabled={meta.current_page === 1}
          />
          <Pagination.Prev
            onClick={() =>
              setMeta({ ...meta, current_page: meta.current_page - 1 })
            }
            disabled={meta.current_page === 1}
          />
          <Pagination.Item>{meta.current_page}</Pagination.Item>
          <Pagination.Next
            onClick={() =>
              setMeta({ ...meta, current_page: meta.current_page + 1 })
            }
            disabled={meta.current_page === meta.last_page}
          />
          <Pagination.Last
            onClick={() => setMeta({ ...meta, current_page: meta.last_page })}
            disabled={meta.current_page === meta.last_page}
          />
        </Pagination>
        <div className="pagination-show">
          <FormControl variant="outlined" className="formControl">
            <InputLabel ref={inputLabel}>Show Entries</InputLabel>
            <Select
              value={meta.per_page}
              input={<OutlinedInput labelWidth={labelWidth} />}
              onChange={(e) => {
                setMeta({ ...meta, per_page: e.target.value });
              }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </FormControl>
          <Typography variant="body1" gutterBottom>
            {meta.current_page &&
              `Showing
                            ${(meta.current_page - 1) * meta.per_page +
                              (meta.total ? 1 : 0)} - 
                            ${
                              meta.current_page * meta.per_page > meta.total
                                ? meta.total
                                : meta.current_page * meta.per_page
                            }
                        of ${meta.total}`}
          </Typography>
        </div>
      </div>
    </StyledJournal>
  );
};

const StyledJournal = styled.div`
  .journal {
    &-btn {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin: 10px 0;

      @media screen and (max-width: 767px) {
        justify-content: unset;
      }
    }
  }
  .sort-arrow {
    opacity: 0.7;
    transition: all 0.3s ease-out;

    &.active {
      opacity: 1;
    }

    &.reverse {
      transform: rotate(180deg);
    }
  }
  .pagination {
    margin: 0;

    @media (max-width: 600px) {
      margin: 10px auto;
    }

    &-wrap {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 10px;

      .MuiSelect-select {
        padding: 7px;
      }
    }

    &-show {
      display: flex;
      align-items: center;
    }
  }

  .formControl {
    width: 150px;
    margin: 5px 10px;
  }

  @media screen and (max-width: 767px) {
    .settingtable {
      overflow: hidden !important;
    }
    table {
      min-width: 540px;
    }
    .table-container {
      overflow: scroll;
      max-height: 700px;
    }

    .journal-btns-container {
      order: 1;
      margin-left: auto;
    }

    @media screen and (max-width: 767px) {
      overflow: auto;
      width: 100%;
      thead th {
        position: sticky;
        top: 0;
        background-color: #f4f4f4;
        z-index: 2;
        padding: 8px 10px;
        font-size: 14px;
        height: 50px;
      }
      tbody td:first-child,
      thead th:first-child {
        width: 48px;
      }
      thead th:nth-child(2) {
        left: 0;
      }
      thead th:nth-child(2),
      thead th:first-child {
        z-index: 11;
      }
      tbody td:nth-child(2) {
        position: sticky;
        left: 0;
      }
      tbody td:first-child,
      tbody td:nth-child(2) {
        z-index: 10;
        background-color: #f9f9f9;
      }
      thead th:first-child {
        z-index: 11;
      }
      .th {
        height: 50px;
        font-weight: 500;
        cursor: pointer;
        padding: 8px 10px;
        font-size: 14px;
      }
      .pagination-wrap {
        margin-top: 20px;
      }
    }
  }
`;

const SizeTooltip = withStyles((theme) => ({
  tooltip: {
    fontSize: 13,
  },
}))(Tooltip);

const TableRow = ({
  item,
  check,
  setCheck,
  setCurrentItem,
  setUpdateModal,
}) => {
  let width = document.body.clientWidth;
  const testTextRef = useRef();
  const cellRef = useRef();
  const [isOverflow, setIsOverflow] = useState(false);

  useEffect(() => {
    if (testTextRef.current.clientWidth > cellRef.current.clientWidth) {
      setIsOverflow(true);
    }
  }, []);

  const getCheck = () => {
    return check.findIndex((id) => id === item.id) !== -1;
  };

  const editCheck = () => {
    let temp = [...check];
    temp.splice(check.indexOf(item.id), 1);
    if (getCheck()) {
      setCheck(temp);
    } else {
      setCheck([...check, item.id]);
    }
  };

  return (
    <Row>
      <td>
        <Checkbox checked={getCheck()} onChange={editCheck} color="primary" />
      </td>
      <td
        className="left-content"
        onClick={() => {
          setCurrentItem(item);
          setUpdateModal(true);
        }}
      >
        {moment(item.note_date, "YYYY-MM-DD").format("MM/DD/YYYY")}
      </td>
      <td
        className="left-content"
        onClick={() => {
          setCurrentItem(item);
          setUpdateModal(true);
        }}
      >
        {item.exercise ? "Exercise" : item.metric?.category_name || "General"}
      </td>
      <td
        className="left-content"
        onClick={() => {
          setCurrentItem(item);
          setUpdateModal(true);
        }}
      >
        {item.exercise?.title || item.metric?.name || "General"}
      </td>
      <td
        className="left-content"
        onClick={() => {
          setCurrentItem(item);
          setUpdateModal(true);
        }}
      >
        <SizeTooltip
          enterTouchDelay={width > 600 ? 700 : 50}
          title={isOverflow ? item.note_text : ""}
          placement="top"
          arrow
        >
          <div className="hidden-text" ref={cellRef}>
            {item.note_text}
          </div>
        </SizeTooltip>
      </td>
      <td
        className="left-content"
        onClick={() => {
          setCurrentItem(item);
          setUpdateModal(true);
        }}
      >
        {item.created_by.full_name}
      </td>
      <div className="test-row" ref={testTextRef}>
        {item.note_text}
      </div>
    </Row>
  );
};

const Row = styled.tr`
  .test-row {
    font-size: 14px;
    position: absolute;
    top: 0px;
    left: 0px;
    visibility: hidden;
  }

  .hidden-text {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  td:hover {
    cursor: pointer;
  }
`;
