import React from "react";
import styled from "styled-components";
import Table from "./Table";
import Card from "./Card";

export default (props) => {
  const { viewType } = props;

  return (
    <PhotoStyled>
      {viewType === 0 ? <Card {...props} /> : <Table {...props} />}
    </PhotoStyled>
  );
};

const PhotoStyled = styled.div`
  @media screen and (max-width: 757px) {
    .settingtable {
      overflow: hidden !important;
    }
    .table-container {
      max-height: 700px;
      overflow: scroll;
    }

    .pagination {
      margin-bottom: 0;
    }
  }
`;
