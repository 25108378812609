import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { ReportsTable } from './ReportsTable';
import {
  getReports,
  deleteReports,
  deleteReport,
  updateReport,
  getReport,
  getReportList,
  showReport,
} from '../../../../crud/analytics.crud';
import Loader from '../../../../components/Loader';
import { DeleteModal } from './DeleteModal/DeleteModal';
import { UpdateModal } from './UpdateModal/UpdateModal';
import Logout from '../../../../pages/auth/Logout';
import './reports-list.scss';
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  OutlinedInput,
  TextField,
} from '@material-ui/core';
import { Pagination } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import * as AlertState from '../../../../store/ducks/auth.duck';
import { InfoModal } from './InfoModal/InfoModal';
import { NewReportModal } from './NewReportModal/NewReportModal';
import { useSelect } from 'downshift';

const ReportsList = () => {
  const dispatch = useDispatch();
  const inputLabel = useRef();
  const [labelWidth, setLabelWidth] = useState(0);

  useEffect(() => {
    inputLabel.current && setLabelWidth(inputLabel.current.offsetWidth);
  }, [inputLabel]);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [dataMeta, setDataMeta] = useState({
    current_page: 1,
    from: 1,
    last_page: 1,
    per_page: 10,
    to: 0,
    total: 0,
    sort: 'updated_at',
    dir: 'desc',
    search: '',
  });

  const [check, setCheck] = useState([]);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isUpdateModal, setIsUpdateModal] = useState(false);
  const [errorsUpdate, setErrorsUpdate] = useState({});
  const [logout, setLogout] = useState(false);
  const [isUserExist, setUserExist] = useState(false);
  const [openModal, setOpenModal] = useState(true);
  const [newReportModal, setNewReportModal] = useState(false);
  let role = useSelector((store) => store.auth.user.roles);

  useEffect(() => {
    if (!role) return;
    getData();
  }, [dataMeta, role]);

  const history = useHistory();
  const userExist = (id, event, item) => {
    return showReport(id)
      .then((req) => {
        if (event === 0) {
          setIsUpdateModal(true);
        } else if (event === 1) {
          setIsDeleteModal(true);
        } else if (event === 2) {
          history.push({ pathname: `report/${id}`, state: { item } });
        }
      })
      .catch(({ response }) => {
        if (response.status === 404) {
          setUserExist(true);
          setOpenModal(true);
          setCheck([]);
          getData();
        }
      });
  };
  const getData = () => {
    setLoader(true);
    setCheck([]);
    getReports(dataMeta)
      .then((res) => {
        setLoader(false);
        setData(res.data.data);

        if (
          JSON.stringify(dataMeta) !==
          JSON.stringify({ ...dataMeta, ...res.data.meta })
        ) {
          setDataMeta({ ...dataMeta, ...res.data.meta });
        }

        if (res.data.meta.last_page < res.data.meta.current_page) {
          setDataMeta({ current_page: res.data.meta.last_page });
        }
        if (!res.data?.meta) {
          dataMeta.total && setDataMeta((prev) => ({ ...prev, total: 0 }));
        }
      })
      .catch((response) => {
        if (response && response.status === 401) setLogout(<Logout />);
        setLoader(false);
      });
  };

  const onSearch = (e) => {
    if (e.target.value.length < 300) {
      setDataMeta({ ...dataMeta, search: e.target.value });
    } else return;
  };

  const selectAll = () => {
    setCheck(data.map((item) => item.id));
  };

  const handleDelete = () => {
    setLoader(true);
    if (check.length === 1) {
      deleteReport(check[0])
        .then(() => {
          getData();
          setCheck([]);
          setLoader(false);
          setIsDeleteModal(false);
          dispatch(
            AlertState.actions.alert({
              text: 'Success deleting',
              variant: true,
            })
          );
        })
        .catch(({ response }) => {
          if (response.status === 404) {
            setIsDeleteModal(false);
            setCheck([]);
            setUserExist(true);
            setOpenModal(true);
            getData();
          }
          setLoader(false);
        });
    } else {
      deleteReports(check)
        .then(() => {
          getData();
          setCheck([]);
          setLoader(false);
          setIsDeleteModal(false);
          dispatch(
            AlertState.actions.alert({
              text: 'Success deleting',
              variant: true,
            })
          );
        })
        .catch(({ response }) => {
          if (response.status === 404) {
            setIsDeleteModal(false);
            setUserExist(true);
            setOpenModal(true);
            setCheck([]);
            getData();
          }
          if (response.status === 422) {
            setIsDeleteModal(false);
            setUserExist(true);
            setOpenModal(true);
            setCheck([]);
            getData();
          }
          setLoader(false);
        });
    }
  };

  const handleUpdate = (date, name, description) => {
    setErrorsUpdate({});
    setLoader(true);
    getReport(check[0])
      .then((res) => {
        updateReport(
          check[0],
          date,
          name,
          description,
          res.data.data,
          res.data.data.type.id
        )
          .then(() => {
            getData();
            setCheck([]);
            setLoader(false);
            setIsUpdateModal(false);
            dispatch(
              AlertState.actions.alert({
                text: 'Report is updated',
                variant: true,
              })
            );
          })
          .catch(({ response }) => {
            setLoader(false);
            if (response && response.data && response.data.errors) {
              const er = Object.keys(response.data.errors);
              let err = {};
              er.forEach((key) => {
                let keys = key.split('.');
                if (keys[0] === 'report' && keys[1]) {
                  err[keys[1]] =
                    typeof response.data.errors[key] === 'string'
                      ? response.data.errors[key]
                      : response.data.errors[key][0];
                } else {
                  err[keys[0]] =
                    typeof response.data.errors[key] === 'string'
                      ? response.data.errors[key]
                      : response.data.errors[key][0];
                }
              });
              setErrorsUpdate(err);
            }
          });
      })
      .catch(({ response }) => {
        if (response.status === 404) {
          setIsUpdateModal(false);
          setUserExist(true);
          setOpenModal(true);
          setCheck([]);
          getData();
        }
        setLoader(false);
      });
  };
  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <>
      {logout}
      <Loader visible={loader} />

      {newReportModal && (
        <NewReportModal
          openModal={newReportModal}
          onClose={() => setNewReportModal(false)}
        />
      )}

      {isUserExist && (
        <InfoModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          onClose={handleClose}
        ></InfoModal>
      )}

      {isDeleteModal && (
        <DeleteModal
          setIsDeleteModal={setIsDeleteModal}
          handleDelete={handleDelete}
        />
      )}

      {isUpdateModal && (
        <UpdateModal
          errors={errorsUpdate}
          isUpdateModal={isUpdateModal}
          setIsUpdateModal={setIsUpdateModal}
          handleUpdate={handleUpdate}
          check={check}
          setErrorsUpdate={setErrorsUpdate}
        />
      )}

      <div className="reports-list">
        <div className="kt-portlet">
          <div className="kt-portlet__body">
            <div className="button-wrapper-2" style={{ marginTop: '0' }}>
              {data.length ? (
                check.length === 0 ? (
                  <Button
                    variant="info"
                    onClick={selectAll}
                    className="button-select-all"
                  >
                    Select All
                  </Button>
                ) : check.length === 1 ? (
                  <div className="action-buttons">
                    <Button
                      className="button"
                      variant="info"
                      onClick={() => userExist(check[0], 0)}
                    >
                      Update
                    </Button>
                    <Button
                      className="button"
                      variant="info"
                      onClick={() => userExist(check[0], 1)}
                    >
                      Delete
                    </Button>
                  </div>
                ) : (
                  <Button
                    variant="info"
                    className="button"
                    onClick={() => setIsDeleteModal(true)}
                  >
                    Delete
                  </Button>
                )
              ) : (
                <div />
              )}
              <div className="main-action-buttons">
                <div className="search-field" style={{ width: '176px' }}>
                  <TextField
                    key={'search'}
                    className="formControl mobile-vis"
                    label="Search"
                    style={{ margin: 5, width: '160px' }}
                    margin="normal"
                    variant="outlined"
                    defaultValue={dataMeta.search}
                    onBlur={onSearch}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        onSearch(e);
                      }
                    }}
                  />
                </div>
                <div className="button-wrapper">
                  <button
                    className="btn-blue btn btn-primary create-report-btn"
                    onClick={() => setNewReportModal(true)}
                  >
                    Create Report
                  </button>
                </div>
              </div>
            </div>

            <ReportsTable
              data={data}
              sort={dataMeta}
              setSort={setDataMeta}
              check={check}
              setCheck={setCheck}
              userExist={userExist}
            />

            <div className="pagination-wrap">
              <Pagination size="lg">
                <Pagination.First
                  onClick={() => setDataMeta({ ...dataMeta, current_page: 1 })}
                  disabled={dataMeta.current_page === 1}
                />
                <Pagination.Prev
                  onClick={() =>
                    setDataMeta({
                      ...dataMeta,
                      current_page: dataMeta.current_page - 1,
                    })
                  }
                  disabled={dataMeta.current_page === 1}
                />
                <Pagination.Item>{dataMeta.current_page}</Pagination.Item>
                <Pagination.Next
                  onClick={() =>
                    setDataMeta({
                      ...dataMeta,
                      current_page: dataMeta.current_page + 1,
                    })
                  }
                  disabled={dataMeta.current_page === dataMeta.last_page}
                />
                <Pagination.Last
                  onClick={() =>
                    setDataMeta({
                      ...dataMeta,
                      current_page: dataMeta.last_page,
                    })
                  }
                  disabled={dataMeta.current_page === dataMeta.last_page}
                />
              </Pagination>
              <div className="pagination-show">
                <FormControl variant="outlined" className="formControl">
                  <InputLabel ref={inputLabel}>Show Entries</InputLabel>
                  <Select
                    value={dataMeta.per_page}
                    input={<OutlinedInput labelWidth={labelWidth} />}
                    onChange={(e) => {
                      setDataMeta({
                        ...dataMeta,
                        per_page: e.target.value,
                        current_page: 1,
                      });
                    }}
                  >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                  </Select>
                </FormControl>
                <Typography variant="body1" gutterBottom>
                  {dataMeta.current_page &&
                    `Showing
                                            ${(dataMeta.current_page - 1) *
                                              dataMeta.per_page +
                                              (dataMeta.total ? 1 : 0)} - 
                                            ${
                                              dataMeta.current_page *
                                                dataMeta.per_page >
                                              dataMeta.total
                                                ? dataMeta.total
                                                : dataMeta.current_page *
                                                  dataMeta.per_page
                                            }
                                        of ${dataMeta.total}`}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportsList;
