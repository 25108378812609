import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as AlertState from "../../../../store/ducks/auth.duck";

import Loader from "../../../../components/Loader";
import styled from "styled-components";
import { Pagination } from "react-bootstrap";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  OutlinedInput,
  Avatar,
  Typography,
} from "@material-ui/core";

import TableList from "../../../../components/TableList";

import { ImgComponent } from "../helpers/ImgComponent";

import { PencilEditIcon } from "../../../../../assets";
import {
  EditFieldModal,
  FIELDS_TYPE,
} from "../modals/EditFieldModal/EditFieldModal";

import "../../table.scss";
import "../../style.scss";

import {
  updateOpportunitieStatus,
  updateOpportunitiesField,
  updateOpportunitiesNote,
  updateOpportunitiesPurchaseDate,
} from "../../../../crud/opportunities.crud";
import { renderTextWithTooltip } from "../helpers/renderTextWithTooltip";

import { OpportunityCardStatus } from "../helpers/renderCreditCardStatus";
import { OpportunitiesStatus } from "../Opportunities/components/OpportunitiesStatus";

import moment from "moment";
import { DatePickerWithIcon } from "../Opportunities/components/OpportunitiesSummary/DatePickerWithIcon";

import { NOTES_TABLE_TYPE } from "../constants/constants";
import { useForecastOpportuninties } from "../hooks/useForecastOpportuninties";
import { priceToView } from "../../../../helpers/thousandSeparator";
import { renderNumericValue } from "../helpers/renderNumericValue";
import { Link } from "react-router-dom";

export const OpportunitiesList = (props) => {
  const plan = useSelector((state) => state.user_info.subscription?.plan?.name);

  const dispatch = useDispatch();

  const initSetting = {
    inintialGroup: props.groupIds,
    startDate: props.startDate,
    endDate: props.endDate,
  };

  const {
    loading,
    error,
    data,
    sort,
    setSort,
    pagination,
    perPage,
    setPage,
    setPerPage,
    setData,
    setStaff,
    setStart,
    setEnd,
  } = useForecastOpportuninties(initSetting);

  useEffect(() => {
    setStaff([props.userId]);
  }, [props.userId]);

  useEffect(() => {
    setStart(props.startDate);
  }, [props.startDate]);

  useEffect(() => {
    setEnd(props.endDate);
  }, [props.endDate]);

  const [clickedRow, setClikedRow] = useState(null);
  const [openModal, setOpenModal] = useState(null);

  const [fetching, setFetching] = useState(false);

  const handleClickOnRow = (value, name, record, title, type) => {
    setClikedRow({ value, name, record, title, type });
    setOpenModal(true);
  };

  const handleClose = () => {
    setClikedRow(null);
    setOpenModal(false);
  };

  const handleUpdateField = async (value) => {
    const profileId = clickedRow.record.profile.id;
    const staffId = props.userId;

    try {
      setFetching(true);

      if (clickedRow.name === "opportunity_note") {
        const req = {
          ...props.period,
          text: value,
          user_id: staffId,
          type: NOTES_TABLE_TYPE.OPPORTUNITIES,
        };
        await updateOpportunitiesNote(profileId, req);
      } else {
        const req = {
          ...props.period,
          package_amount: value,
          user_id: staffId,
        };
        await updateOpportunitiesField(profileId, req);
      }

      const updateData = data.map((item) =>
        item.values.profile.id === profileId
          ? { values: { ...item.values, [clickedRow.name]: value } }
          : item
      );

      setData(updateData);

      handleClose();
      dispatch(
        AlertState.actions.alert({
          text: "Session info is updated",
          variant: true,
        })
      );
    } catch (error) {
      console.dir(error);
      dispatch(
        AlertState.actions.alert({
          text: error?.message || "Something went wrong!",
          variant: false,
        })
      );
    } finally {
      setFetching(false);
    }
  };

  const handleUpdatePurchaseDate = async (value, record) => {
    const profileId = record.profile.id;
    const staffId = props.userId;
    setFetching(true);
    try {
      const req = {
        ...props.period,
        purchase_date: moment(value).format("YYYY-MM-DD"),
        user_id: staffId,
      };

      await updateOpportunitiesPurchaseDate(profileId, req);

      const updateData = data.map((item) =>
        item.values.profile.id === profileId
          ? { values: { ...item.values, [record.name]: value } }
          : item
      );

      setData(updateData);

      handleClose();
      dispatch(
        AlertState.actions.alert({
          text: "Session info is updated",
          variant: true,
        })
      );
    } catch (error) {
      console.dir(error);
      dispatch(
        AlertState.actions.alert({
          text: error?.message || "Something went wrong!",
          variant: false,
        })
      );
    } finally {
      setFetching(false);
    }
  };

  const handleUpdateStatus = async (value, record) => {
    const profileId = record.profile.id;
    const staffId = props.userId;
    setFetching(true);
    try {
      const req = {
        ...props.period,
        status_value: value,
        user_id: staffId,
      };

      await updateOpportunitieStatus(profileId, req);

      const updateData = data.map((item) =>
        item.values.profile.id === profileId
          ? { values: { ...item.values, [record.name]: value } }
          : item
      );

      setData(updateData);

      handleClose();
      dispatch(
        AlertState.actions.alert({
          text: "Session info is updated",
          variant: true,
        })
      );
    } catch (error) {
      console.dir(error);
      dispatch(
        AlertState.actions.alert({
          text: error?.message || "Something went wrong!",
          variant: false,
        })
      );
    } finally {
      setFetching(false);
    }
  };

  const header = [
    {
      name: "staff_names",
      title: "Staff",
      render: (value) => renderTextWithTooltip(value || "--"),
    },
    {
      name: "profile",
      title: "Profile",
      render: (profile, name, record) => {
        return (
          <NameWrapper>
            {profile?.picture ? (
              <ImgComponent
                src={profile.picture}
                alt="img"
                className="profile-photo"
              />
            ) : (
              <Avatar className="profile-photo">{`${profile?.first_name[0]}${profile?.last_name[0]}`}</Avatar>
            )}
            <p>
              <Link to={`/profile/${profile.id}/overview`}>
                {profile?.first_name} {profile?.last_name}
              </Link>
            </p>
          </NameWrapper>
        );
      },
    },
    {
      name: "last_session_date",

      title: (
        <>
          <p style={{ margin: 0 }}>Last </p>
          <p style={{ margin: 0 }}>Session Date</p>
        </>
      ),
      render: (date) => (date ? moment(date).format("MM/DD/YYYY") : "--"),
    },
    {
      name: "count_checkins",

      title: (
        <>
          <p style={{ margin: 0 }}>Last 30-Day </p>
          <p style={{ margin: 0 }}>Check-Ins</p>
        </>
      ),
      render: renderNumericValue,
    },

    {
      name: "package_amount",
      title: "Amount",
      render: (value) => priceToView(value),
    },
    {
      name: "session_duration",

      title: (
        <>
          <p style={{ margin: 0 }}>Session </p>
          <p style={{ margin: 0 }}>Duration</p>
        </>
      ),
      render: renderNumericValue,
    },
    {
      name: "sessions_remaining",

      title: (
        <>
          <p style={{ margin: 0 }}>Session </p>
          <p style={{ margin: 0 }}>Remaining</p>
        </>
      ),
      render: renderNumericValue,
    },
    {
      name: "sessions_completed",

      title: (
        <>
          <p style={{ margin: 0 }}>Session </p>
          <p style={{ margin: 0 }}>Completed</p>
        </>
      ),
      render: renderNumericValue,
    },
    {
      name: "credit_card_status",

      title: (
        <>
          <p style={{ margin: 0 }}>Credit Card </p>
          <p style={{ margin: 0 }}> Status</p>
        </>
      ),
      render: (value) => <OpportunityCardStatus status={value} />,
    },
    {
      name: "opportunity_status",

      title: (
        <>
          <p style={{ margin: 0 }}>Opportunity </p>
          <p style={{ margin: 0 }}>Status</p>
        </>
      ),
      render: (value, name, record) => (
        <OpportunitiesStatus
          onSave={(value) => handleUpdateStatus(value, record)}
          value={value}
        />
      ),
    },
    {
      name: "purchase_date",

      title: (
        <>
          <p style={{ margin: 0 }}>Expected </p>
          <p style={{ margin: 0 }}>Purchase Date</p>
        </>
      ),
      render: (value, name, record) => (
        <DatePickerWithIcon
          value={value ? moment(value).format("DD/MM/YYYY") : null}
          title={"Exp. Close Date"}
          onSave={(value) => handleUpdatePurchaseDate(value, record)}
        />
      ),
    },
    {
      name: "exp_package_amount",

      title: (
        <>
          <p style={{ margin: 0 }}>Expected </p>
          <p style={{ margin: 0 }}>Amount</p>
        </>
      ),
      render: (value, name, record) => (
        <EditableCol>
          <span>{priceToView(value)}</span>
          <span
            className="pencil-icon"
            onClick={() =>
              handleClickOnRow(
                value,
                name,
                record,
                "Exp. Product",
                FIELDS_TYPE.INPUT
              )
            }
          >
            <PencilEditIcon />
          </span>
        </EditableCol>
      ),
    },
    {
      name: "opportunity_note",

      title: (
        <>
          <p style={{ margin: 0 }}>Opportunity </p>
          <p style={{ margin: 0 }}>Notes</p>
        </>
      ),
      render: (value, name, record) => (
        <EditableCol>
          {renderTextWithTooltip(value?.text || value || "--")}

          <span
            className="pencil-icon"
            onClick={() =>
              handleClickOnRow(
                value?.text || value,
                name,
                record,
                "Opportunity Notes",
                FIELDS_TYPE.AREA
              )
            }
          >
            <PencilEditIcon />
          </span>
        </EditableCol>
      ),
    },
  ];

  useEffect(() => {
    if (error) {
      dispatch(
        AlertState.actions.alert({
          text: error?.response?.data?.message || "Something went wrong!",
          variant: false,
        })
      );
    }
  }, [error]);

  return (
    <StyledGroupLists className="nowrap">
      <Loader visible={loading} />

      <Portlet>
        <PortletBody>
          <FiltersBlock>
            <TitleBlock>
              <p className="heading-title">Group: </p>
              <p>
                {props?.selectedGroup?.length
                  ? props?.selectedGroup?.map((item) => item.name).join(",")
                  : "--"}
              </p>
            </TitleBlock>
            <TitleBlock>
              <p className="heading-title">Staff:</p>
              <p>{props?.selectedUsers?.map((item) => item.title).join(",")}</p>
            </TitleBlock>
          </FiltersBlock>
          <TableList
            header={header}
            data={data}
            check={[]}
            sort={sort}
            setSort={(newSort) => {
              if (!sort) {
                return setSort(newSort);
              }
              if (sort[0] !== newSort[0]) {
                return setSort([newSort[0], "asc"]);
              }

              if (sort[1] === "desc") {
                return setSort([undefined, undefined]);
              }
              setSort(newSort);
            }}
            plan={plan}
          />
          {!!pagination?.total && (
            <div className="pagination-wrap">
              <Pagination size="lg">
                <Pagination.First
                  disabled={pagination.current_page === 1}
                  onClick={() => setPage(1)}
                />
                <Pagination.Prev
                  disabled={pagination.current_page === 1}
                  onClick={() => setPage((prev) => prev - 1)}
                />

                <Pagination.Item>{pagination.current_page}</Pagination.Item>

                <Pagination.Next
                  disabled={pagination.last_page === pagination.current_page}
                  onClick={() => setPage((prev) => prev + 1)}
                />
                <Pagination.Last
                  disabled={pagination.last_page === pagination.current_page}
                  onClick={() => setPage(pagination.last_page)}
                />
              </Pagination>

              <div className="pagination-show">
                <FormControl variant="outlined" className="formControl">
                  <InputLabel>Show Entries</InputLabel>
                  <Select
                    value={perPage}
                    input={<OutlinedInput />}
                    onChange={(e) => {
                      setPerPage(e.target.value);
                    }}
                  >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                  </Select>
                </FormControl>
                <Typography variant="body1" gutterBottom>
                  {`Showing ${pagination.from} - ${pagination.to} of ${pagination.total}`}
                </Typography>
              </div>
            </div>
          )}
        </PortletBody>
      </Portlet>
      {openModal && (
        <EditFieldModal
          title={clickedRow?.title}
          value={clickedRow?.value}
          type={clickedRow?.type}
          open={openModal}
          onClose={handleClose}
          onSave={handleUpdateField}
          loading={fetching}
        />
      )}
    </StyledGroupLists>
  );
};

const StyledGroupLists = styled.div`
  .MuiTab-wrapper {
    font-size: 12px;
    font-weight: 700;
  }

  .btn {
    margin-right: 15px;
  }

  .btn-toolbar {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;

    &__group {
      display: flex;
      align-items: center;

      .formControl {
        margin-left: 0;
      }
    }
  }

  .edit-all {
    height: 100%;
    border-radius: 4px;
    background-color: #eef1ff;
    padding: 0 12px;
    font-weight: 600;
    font-size: 13px;
    line-height: 19px;
    color: #5d78ff;
    display: flex;
    align-items: center;
  }

  .pagination {
    margin: 0;

    @media (max-width: 600px) {
      margin: 10px auto;
    }

    &-wrap {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 10px;

      .MuiSelect-select {
        padding: 7px;
      }
    }

    &-show {
      display: flex;
      align-items: center;
      min-width: 290px;
    }
  }

  .formControl {
    width: 150px;
    margin: 5px 10px;
  }
  & .settingtable {
    overflow-y: auto;
  }
`;

const FiltersBlock = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
`;

const EditableCol = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  & .pencil-icon {
    cursor: pointer;
  }
`;

const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  & p {
    margin-bottom: 0;
    white-space: nowrap;
  }
`;

const TitleBlock = styled.p`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;

  & .heading-title {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
  }
`;
