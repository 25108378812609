import { Portlet, PortletBody } from "../../../partials/content/Portlet";
import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import * as AlertState from "../../../store/ducks/auth.duck";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import { Button, Pagination, Modal } from "react-bootstrap";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
  ListItemText,
  OutlinedInput,
  FormHelperText,
  Typography,
} from "@material-ui/core";
import { Formik } from "formik";
import TableList from "./TableGroupList";
import {
  getGroup,
  deleteGroups,
  fieldsGroups,
  updateGroups,
  getGroupById,
} from "../../../crud/info.crud";
import * as media from "../../../crud/media.crud";

import Logout from "../../auth/Logout";
import Loader from "../../../components/Loader";
import ModalCreateFromCRM from "./ModalCreateFromCrm";
import { useSelector } from "react-redux";
import "../table.scss";
import "../style.scss";
import Cancel from "@material-ui/icons/Cancel";
import * as builder from "../../../../_metronic/ducks/builder";
import DropZone from "../../../components/DropZone";
import { InputAdornment } from "@material-ui/core";
import { ChromePicker } from "react-color";
import { getAccountByToken } from "../../../crud/auth.crud";
import { setAccount } from "../../../store/actions";

const ImgComponent = ({ src, alt, className, style }) => {
  const [url, setUrl] = useState("");

  useEffect(() => {
    src &&
      media
        .getImg(src)
        .then((res) => setUrl(res))
        .catch((err) => setUrl(err));
  }, [src]);
  return url && <img src={url} alt={alt} className={className} style={style} />;
};

export default () => {
  document.title = "TrainerMetrics - Groups List";
  const crm = useSelector((state) =>
    state.user_info.CRM_list.find((elem) => elem.is_activated === true)
  );
  const store = useSelector(({ builder }) => builder);
  const headerLogo = builder.selectors.getLogo({ builder: store });

  const dispatch = useDispatch();
  const [logout, setLogout] = useState(null);
  const [data, setData] = useState([]);
  const [show, setShow] = useState("10");
  const [page, setPage] = useState(false);
  const [pagination, setPagination] = useState(1);
  const [check, setCheck] = useState([]);
  const [errors, setErrors] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [staff, setStaff] = useState([]);
  const [supervisor, setSupervisor] = useState([]);
  const [updateInitial, setUpdateInitial] = useState({
    name: "",
    description: "",
    supervisor: [],
    staff: [],
    primary_color: "",
    file_id: "",
  });
  const [loaderFieldsGroups, setLoaderFieldsGroups] = useState(true);
  const [loaderGroups, setLoaderGroups] = useState(false);
  const [loaderDeleteGroups, setLoaderDeleteGroups] = useState(false);
  const [loaderGroupById, setLoaderGroupById] = useState(false);
  const [loaderUpdate, setLoaderUpdate] = useState(false);
  const [loader, setLoader] = useState(false);
  const [sort, setSort] = useState(["name", "asc"]);
  const [dropdown, setDropdown] = useState(false);
  const [modalCreateFromCRM, setModalCreateFromCRM] = useState(false);
  const [photo, setPhoto] = useState("");
  const [showPicker, setShowPicker] = useState(false);
  const wrapperRef = useRef(null);
  const layoutConfigs = store.layoutConfig;

  const [isPhotoLoading, setIsPhotoLoading] = useState(false);
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    setLoaderFieldsGroups(true);
    fieldsGroups()
      .then((res) => {
        setLoaderFieldsGroups(false);
        setStaff(res.data.data.find((item) => item.name === "staff").options);
        setSupervisor(
          res.data.data.find((item) => item.name === "supervisor").options
        );
      })
      .catch(({ response }) => {
        response &&
          response.data &&
          dispatch(
            AlertState.actions.alert({
              text: response.data.message || response.data.error,
              variant: false,
            })
          );
        setLoaderFieldsGroups(false);
        if (response && response.status === 401) {
          setLogout(<Logout />);
        } else if (response && response.status === 403) {
          setLogout(<Redirect to="/profile-list" />);
        }
      });
  }, []);

  useEffect(() => {
    loadData();
  }, [pagination, show]);

  const loadData = () => {
    setLoaderGroups(true);
    getGroup()
      .then((res) => {
        setCheck([]);
        setLoaderGroups(false);
        setData(res.data.data);
      })
      .catch(({ response }) => {
        response &&
          response.data &&
          dispatch(
            AlertState.actions.alert({
              text: response.data.message || response.data.error,
              variant: false,
            })
          );
        setLoaderGroups(false);
        if (response && response.status === 401) {
          setLogout(<Logout />);
        } else if (response && response.status === 403) {
          setLogout(<Redirect to="/profile-list" />);
        }
      });
  };

  const [search, setSearch] = useState("");
  const [filterData, setFilterData] = useState([]);

  useEffect(() => {
    filterDataF();
  }, [search, data, pagination, sort]);

  useEffect(() => {
    setPagination(1);
  }, [show, search]);

  const filterDataF = () => {
    const filter = data.filter((item) => {
      if (search.length < 2) return item;
      return (
        `${item.description || ""}`
          .toUpperCase()
          .indexOf(search.toUpperCase()) !== -1 ||
        `${item.name || ""}`.toUpperCase().indexOf(search.toUpperCase()) !==
          -1 ||
        item.supervisor
          .join(" ")
          .toUpperCase()
          .indexOf(search.toUpperCase()) !== -1 ||
        `${item.total_profiles || ""}`
          .toUpperCase()
          .indexOf(search.toUpperCase()) !== -1 ||
        `${item.total_staff || ""}`
          .toUpperCase()
          .indexOf(search.toUpperCase()) !== -1
      );
    });

    const sortFilter = [...filter].sort((a, b) => {
      if (
        sort[0] !== "name" &&
        typeof a[sort[0]] === "string" &&
        typeof b[sort[0]] === "string"
      ) {
        if (a[sort[0]].toUpperCase() > b[sort[0]].toUpperCase()) {
          return 1;
        }
        if (a[sort[0]].toUpperCase() < b[sort[0]].toUpperCase()) {
          return -1;
        }
        return 0;
      } else if (
        sort[0] === "name" &&
        typeof a.last_name === "string" &&
        typeof b.last_name === "string"
      ) {
        if (
          a.last_name.toUpperCase() + a.first_name.toUpperCase() >
          b.last_name.toUpperCase() + b.first_name.toUpperCase()
        ) {
          return 1;
        }
        if (
          a.last_name.toUpperCase() + a.first_name.toUpperCase() <
          b.last_name.toUpperCase() + b.first_name.toUpperCase()
        ) {
          return -1;
        }
        return 0;
      } else {
        return 0;
      }
    });

    const numPage = Math.floor(sortFilter.length / parseInt(show));

    setPage({
      last_page:
        numPage === sortFilter.length / parseInt(show) ? numPage : numPage + 1,
      per_page: parseInt(show),
      total: sortFilter.length,
    });

    setFilterData(
      sort[1] === "asc"
        ? sortFilter.splice((pagination - 1) * parseInt(show), parseInt(show))
        : sortFilter
            .reverse()
            .splice((pagination - 1) * parseInt(show), parseInt(show))
    );
  };

  useEffect(() => {
    setPage({
      ...page,
      current_page: pagination,
    });
  }, [pagination]);

  const checkAll = () => {
    setCheck(() => data.map((item) => item.id));
  };

  const deleteGropsAction = () => {
    setLoaderDeleteGroups(true);
    deleteGroups({
      groups: check,
    })
      .then((res) => {
        setLoaderDeleteGroups(false);
        setCheck([]);
        setPagination(1);
        loadData();
        dispatch(
          AlertState.actions.alert({
            text: "Success deleting",
            variant: true,
          })
        );
      })
      .catch(({ response }) => {
        response &&
          response.data &&
          dispatch(
            AlertState.actions.alert({
              text: response.data.message || response.data.error,
              variant: false,
            })
          );
        setLoaderDeleteGroups(false);
        if (response && response.status === 401) {
          setLogout(<Logout />);
        } else if (response && response.status === 403) {
          setLogout(<Redirect to="/profile-list" />);
        }
      });
  };

  const GroupById = () => {
    setLoaderGroupById(true);
    getGroupById(check[0])
      .then((res) => {
        setLoaderGroupById(false);
        setUpdateInitial({
          id: res.data.data.find((item) => item.name === "id").value,
          name: res.data.data.find((item) => item.name === "name").value,
          description: res.data.data.find((item) => item.name === "description")
            .value,
          supervisor: Object.values(
            res.data.data.find((item) => item.name === "supervisor").value
          ).map((item) => item.id),
          staff: Object.values(
            res.data.data.find((item) => item.name === "staff").value
          ).map((item) => item.id),
          primary_color:
            res.data.data.find((item) => item.name === "primary_color").value ||
            layoutConfigs.custom_color,
          file_id:
            res.data.data.find((item) => item.name === "file_id").value?.path ||
            headerLogo,
        });
        setPhoto(
          res.data.data.find((item) => item.name === "file_id").value?.path ||
            headerLogo
        );
      })
      .catch(({ response }) => {
        setLoaderGroupById(false);
        if (response && response.status === 401) {
          setLogout(<Logout />);
        } else if (response && response.status === 403) {
          setLogout(<Redirect to="/profile-list" />);
        }
      });
  };

  const handleLogoRemove = () => {
    setPhoto("");
    setUpdateInitial((prev) => ({ ...prev, file_id: null }));
    setTouched(true);
  };
  const handleInputClick = () => {
    setShowPicker(!showPicker);
  };
  const handleColorChange = (color, event) => {
    const colorVal = color.hex;
    setUpdateInitial((prev) => ({ ...prev, primary_color: colorVal }));
    setTouched(true);
  };
  const handleLogo = (photoItem) => {
    setPhoto(photoItem);
    setUpdateInitial((prev) => ({ ...prev, file_id: photoItem?.id }));
    setTouched(true);
  };
  const inputLabel = useRef();
  const [labelWidth, setLabelWidth] = useState(0);

  useEffect(() => {
    inputLabel.current && setLabelWidth(inputLabel.current.offsetWidth);
  }, [inputLabel]);

  const inputLabel_2 = useRef();
  const [labelWidth_2, setLabelWidth_2] = useState(0);

  useEffect(() => {
    inputLabel_2.current && setLabelWidth_2(inputLabel_2.current.offsetWidth);
  }, [inputLabel_2, updateModal]);

  const inputLabel_3 = useRef();
  const [labelWidth_3, setLabelWidth_3] = useState(0);

  useEffect(() => {
    inputLabel_3.current && setLabelWidth_3(inputLabel_3.current.offsetWidth);
  }, [inputLabel_3, updateModal]);

  const buttonRef = useRef();
  useEffect(() => {
    if (!crm) return;
    function clickOutside(e) {
      if (buttonRef && !buttonRef.current.contains(e.target)) {
        setDropdown(false);
      }
    }

    document.addEventListener("mousedown", clickOutside);
    return () => document.removeEventListener("mousedown", clickOutside);
  }, [buttonRef, crm]);

  return (
    <StyledGroupLists>
      {logout}
      <Loader
        visible={
          loaderFieldsGroups ||
          loaderGroups ||
          loaderDeleteGroups ||
          loaderGroupById ||
          loader
        }
      />

      <Modal
        show={deleteModal}
        onHide={(e) => {
          setDeleteModal(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete checked groups?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={(e) => {
              setDeleteModal(false);
            }}
          >
            No
          </Button>
          <Button
            onClick={(e) => {
              setDeleteModal(false);
              deleteGropsAction();
            }}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={updateModal}
        onHide={(e) => {
          setUpdateModal(false);
        }}
      >
        <Formik
          initialValues={updateInitial}
          enableReinitialize={true}
          validate={(values) => {
            const temp = {};

            if (!values.name) {
              temp.name = "This value should not be blank";
            } else if (values.name.length > 50 || values.name.length < 2) {
              temp.name = "Field is not valid";
            } else if (/[^A-Za-z0-9 ]/g.test(values.name)) {
              temp.name = "Field is not valid";
            }

            if (
              values.description &&
              (values.description.length > 50 || values.description.length < 2)
            ) {
              errors.description = "Field is not valid";
            }

            setErrors(temp);
            return temp;
          }}
          onSubmit={(values, { setStatus, setSubmitting }) => {
            setLoaderUpdate(true);
            const req = { ...values };
            if (typeof values.file_id === "string" && !!values.file_id) {
              delete req.file_id;
            }
            updateGroups(values.id, req)
              .then((res) => {
                setLoaderUpdate(false);
                setStatus(false);
                setUpdateModal(false);
                setCheck([]);
                loadData();
                dispatch(
                  AlertState.actions.alert({
                    text: "Group is updated",
                    variant: true,
                  })
                );
              })
              .catch(({ response }) => {
                setLoaderUpdate(false);
                response &&
                  response.data &&
                  dispatch(
                    AlertState.actions.alert({
                      text: response.data.message || response.data.error,
                      variant: false,
                    })
                  );
                if (response && response.status === 401) {
                  setLogout(<Logout />);
                }
                !!response &&
                  !!response.data &&
                  !!response.data.errors &&
                  setErrors({
                    name:
                      response.data.errors.name && response.data.errors.name[0],
                    description:
                      response.data.errors.description &&
                      response.data.errors.description[0],
                    supervisor:
                      response.data.errors.supervisor &&
                      response.data.errors.supervisor[0],
                    staff:
                      response.data.errors.staff &&
                      response.data.errors.staff[0],
                  });
                setSubmitting(false);
                setStatus(response.data.message);
              })
              .finally(() => {
                if (touched) {
                  setTouched(false);

                  getAccountByToken().then((res) => {
                    setAccount(res.data.data);
                  });
                }
              });
          }}
        >
          {({
            values,
            status,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form
              noValidate={true}
              autoComplete="off"
              className="kt-form root"
              onSubmit={handleSubmit}
            >
              <Modal.Header closeButton>
                <Modal.Title>Update Group</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <Loader visible={loaderUpdate} />
                {check.length > 1 ? (
                  "Please select 1 group only."
                ) : check.length === 1 ? (
                  <>
                    <div className="row">
                      <div className="col-12">
                        {status && (
                          <div role="alert" className="alert alert-danger">
                            <div className="alert-text">{status}</div>
                          </div>
                        )}
                        <div className="form-group">
                          <TextField
                            key="43"
                            variant="outlined"
                            type="text"
                            label="Name"
                            margin="normal"
                            className="kt-width-full"
                            name="name"
                            inputProps={{
                              maxLength: 50,
                            }}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.name || ""}
                            helperText={touched.name && errors.name}
                            error={Boolean(touched.name && errors.name)}
                          />

                          <TextField
                            key="44"
                            variant="outlined"
                            type="text"
                            label="Description"
                            margin="normal"
                            multiline
                            className="kt-width-full"
                            name="description"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.description || ""}
                            helperText={
                              touched.description && errors.description
                            }
                            error={Boolean(
                              touched.description && errors.description
                            )}
                          />

                          <div className="MuiFormControl-root MuiTextField-root kt-width-full MuiFormControl-marginNormal">
                            <FormControl
                              variant="outlined"
                              className="formControl"
                            >
                              <InputLabel
                                ref={inputLabel_2}
                                htmlFor="select-multiple-checkbox"
                              >
                                Supervisor
                              </InputLabel>
                              <Select
                                multiple
                                value={values.supervisor}
                                name="supervisor"
                                onChange={handleChange}
                                input={
                                  <OutlinedInput
                                    labelWidth={labelWidth_2}
                                    id="select-multiple-checkbox"
                                  />
                                }
                                renderValue={(selected) =>
                                  selected
                                    .map(
                                      (item) =>
                                        supervisor.find(
                                          (elem) => elem.id === item
                                        )?.title
                                    )
                                    .join(", ")
                                }
                                inputProps={{
                                  name: "supervisor",
                                }}
                                MenuProps={{
                                  PaperProps: {
                                    style: {
                                      maxHeight: 250,
                                      width: 250,
                                    },
                                  },
                                }}
                              >
                                {supervisor.map((item) => (
                                  <MenuItem key={item.id} value={item.id}>
                                    <ListItemText primary={item.title} />
                                  </MenuItem>
                                ))}
                                {supervisor.length === 0 && (
                                  <MenuItem disabled>None</MenuItem>
                                )}
                              </Select>
                              <FormHelperText>
                                Select one or more
                              </FormHelperText>
                            </FormControl>
                          </div>

                          <div className="MuiFormControl-root MuiTextField-root kt-width-full MuiFormControl-marginNormal">
                            <FormControl
                              variant="outlined"
                              className="formControl"
                            >
                              <InputLabel
                                ref={inputLabel_3}
                                htmlFor="select-multiple-checkbox2"
                              >
                                Staff
                              </InputLabel>
                              <Select
                                multiple
                                value={values.staff}
                                name="staff"
                                onChange={handleChange}
                                input={
                                  <OutlinedInput
                                    labelWidth={labelWidth_3}
                                    id="select-multiple-checkbox2"
                                  />
                                }
                                renderValue={(selected) =>
                                  selected
                                    .map(
                                      (item) =>
                                        staff.find((elem) => elem.id === item)
                                          .title
                                    )
                                    .join(", ")
                                }
                                inputProps={{
                                  name: "staff",
                                }}
                                MenuProps={{
                                  PaperProps: {
                                    style: {
                                      maxHeight: 250,
                                      width: 250,
                                    },
                                  },
                                }}
                              >
                                {staff.map((item) => (
                                  <MenuItem key={item.id} value={item.id}>
                                    <ListItemText primary={item.title} />
                                  </MenuItem>
                                ))}
                                {staff.length === 0 && (
                                  <MenuItem disabled>None</MenuItem>
                                )}
                              </Select>
                              <FormHelperText>
                                Select one or more
                              </FormHelperText>
                            </FormControl>
                          </div>
                          <Row>
                            <div className={"col-4"}>
                              <p>Primary Color</p>
                            </div>
                            <div className={"col-8"}>
                              <div className="color-info">
                                <TextField
                                  key="48"
                                  id="primary_color"
                                  className={"color_field"}
                                  variant="outlined"
                                  value={updateInitial.primary_color}
                                  fullWidth
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment
                                        className={"color_preview"}
                                      >
                                        <span
                                          style={{
                                            backgroundColor:
                                              updateInitial.primary_color,
                                          }}
                                        ></span>
                                      </InputAdornment>
                                    ),
                                  }}
                                  onClick={handleInputClick}
                                />
                                {showPicker && (
                                  <div ref={wrapperRef}>
                                    <ChromePicker
                                      className={"color_picker"}
                                      color={updateInitial.primary_color}
                                      onChangeComplete={handleColorChange}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          </Row>
                          <Row>
                            <div className={"col-4"}>
                              <p>Logo</p>
                            </div>
                            <div className={"col-8"}>
                              <div className="info-block__photo">
                                {typeof updateInitial.file_id === "string" &&
                                !!updateInitial.file_id ? (
                                  <div
                                    className="info-block__photo-preview"
                                    style={{ height: "auto" }}
                                  >
                                    <ImgComponent
                                      src={updateInitial.file_id}
                                      alt="propfile"
                                      style={{ height: "35px" }}
                                    />
                                    <div className="delete">
                                      <Cancel onClick={handleLogoRemove} />
                                    </div>
                                  </div>
                                ) : (
                                  <DropZone
                                    submit={handleLogo}
                                    photo={photo}
                                    setIsLoading={setIsPhotoLoading}
                                  />
                                )}
                              </div>
                            </div>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  "Select group"
                )}
              </Modal.Body>
              <Modal.Footer>
                {check.length > 1 ? (
                  <Button
                    variant="primary"
                    onClick={(e) => {
                      setUpdateModal(false);
                    }}
                  >
                    Ok
                  </Button>
                ) : (
                  <>
                    <Button
                      variant="primary"
                      onClick={(e) => {
                        setUpdateModal(false);
                      }}
                    >
                      Close
                    </Button>
                    <Button
                      className="btn-blue"
                      disabled={isSubmitting || isPhotoLoading}
                      onClick={(e) => {
                        handleSubmit();
                      }}
                    >
                      Save
                    </Button>
                  </>
                )}
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </Modal>

      <ModalCreateFromCRM
        setLoader={setLoader}
        modal={modalCreateFromCRM}
        setModal={setModalCreateFromCRM}
      />

      <Portlet>
        <PortletBody>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              className="btn-blue"
              onClick={() => {
                if (crm) {
                  setDropdown(true);
                } else {
                  setLogout(<Redirect to="/group-create" />);
                }
              }}
              style={{ whiteSpace: "nowrap", marginRight: 0 }}
            >
              Create Group
            </Button>

            <div
              className="buttons-dropdown"
              style={{ display: `${dropdown ? "" : "none"}` }}
              ref={buttonRef}
            >
              <div className="buttons-dropdown__elem">
                <span
                  onClick={() => setLogout(<Redirect to="/group-create" />)}
                >
                  Create Group
                </span>
              </div>
              <div className="buttons-dropdown__elem">
                <span onClick={() => setModalCreateFromCRM(true)}>
                  Create Group from CRM
                </span>
              </div>
              <div
                className="buttons-dropdown__arrow"
                onClick={() => setDropdown(false)}
              >
                {">"}
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "10px 0",
              gap: "10px"
            }}
          >
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              {check.length > 0 ? (
                <>
                  <Button
                    variant="primary"
                    onClick={(e) => {
                      GroupById();
                      setUpdateModal(true);
                    }}
                  >
                    Update
                  </Button>
                  <Button onClick={(e) => setDeleteModal(true)}>Delete</Button>
                </>
              ) : (
                <Button variant="info" onClick={checkAll}>
                  Edit All
                </Button>
              )}
            </div>

            <div className={`search-wrap`}>
              <TextField
                key={"search"}
                className="formControl"
                label="Search"
                margin="normal"
                variant="outlined"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    setSearch(e.target.value);
                  }
                }}
              />
            </div>
          </div>
          <TableList
            data={filterData}
            check={check}
            setCheck={setCheck}
            sort={sort}
            setSort={setSort}
          />
          <div className="pagination-wrap">
            <Pagination size="lg">
              <Pagination.First
                onClick={() => setPagination(1)}
                disabled={pagination === 1}
              />
              <Pagination.Prev
                onClick={() => setPagination((p) => p - 1)}
                disabled={pagination === 1}
              />

              <Pagination.Item>{pagination}</Pagination.Item>

              <Pagination.Next
                onClick={() => setPagination((p) => p + 1)}
                disabled={pagination === page.last_page}
              />
              <Pagination.Last
                onClick={() => setPagination(page.last_page)}
                disabled={pagination === page.last_page}
              />
            </Pagination>
            <div className="pagination-show">
              <FormControl variant="outlined" className="formControl">
                <InputLabel ref={inputLabel} htmlFor={`outlined-age-simple`}>
                  Show Entries
                </InputLabel>
                <Select
                  value={show}
                  onChange={(e) => {
                    setShow(e.target.value);
                  }}
                  input={
                    <OutlinedInput
                      labelWidth={labelWidth}
                      id={`outlined-age-simple`}
                    />
                  }
                  className="selectEmpty"
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </Select>
              </FormControl>
              <Typography variant="body1" gutterBottom>
                {page &&
                  `Showing ${(pagination - 1) * page.per_page +
                    (page.total ? 1 : 0)} - ${
                    pagination * page.per_page > page.total
                      ? page.total
                      : pagination * page.per_page
                  } of ${page.total}`}
              </Typography>
            </div>
          </div>
        </PortletBody>
      </Portlet>
    </StyledGroupLists>
  );
};

const StyledGroupLists = styled.div`
  .btn {
    margin-right: 15px;
    white-space: nowrap;

    @media screen and (max-width: 767px) {
      margin-right: 0;
      height: 40px;
    }
  }

  .btn-toolbar {
    button {
      margin-right: 10px;
    }
  }

  .edit-all {
    height: 100%;
    border-radius: 4px;
    background-color: #eef1ff;
    padding: 0 12px;
    font-weight: 600;
    font-size: 13px;
    line-height: 19px;
    color: #5d78ff;
    display: flex;
    align-items: center;
  }

  .pagination {
    margin: 0;

    @media (max-width: 600px) {
      margin: 10px auto;
    }

    &-wrap {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 10px;

      .MuiSelect-select {
        padding: 7px;
      }
    }

    &-show {
      display: flex;
      align-items: center;
      min-width: 290px;
    }
  }

  .formControl {
    width: 150px;
    margin: 5px 10px;
  }

  .search-wrap {
    @media  screen and (max-width: 450px) {
      width: 100%;
    }
  }

  .search-wrap .formControl {
    margin-right: 0;

    @media  screen and (max-width: 450px) {
      width: 100%;
      margin: 0;
    }
  }
`;

const Row = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  padding: 10px 0;
  & .info-block__photo {
    margin: 0;
  }
  & .color_preview {
    width: 60px;
    height: 60px;
    max-height: 3em;
    background-color: #cecece;
    & span {
      width: 50%;
      height: 50%;
      margin: 0 10px;
    }
  }
`;
