import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import trash from '../../../../../../_metronic/_assets/media/svg-icon/delete.svg';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { TextField } from "@material-ui/core";
import ConfirmModal from '../../../../../components/ConfirmationModal';
import Dropdown from '../../../../../components/DropdownComponent';
import { fetchExercise, getPreviousExercise } from '../../../../../crud/library.crud';
import { ExerciseComponent } from './ExerciseComponent/ExerciseComponent';
import { validateSectionTitle, handleChangeSectionTitle } from '../validations';
import CustomTimeInput from '../../../../../components/CustomTimeInput/CustomTimeInput';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import './dnd.scss';
import { getNotesV2 } from '../../../../../crud/notes.crud';
import { putNumbersFunc } from '../functions';
import { Add, CheckCircle } from '@material-ui/icons';

const StyledToolTip = withStyles((theme) => ({
    tooltip: {
        boxShadow: theme.shadows[1],
        fontSize: 14,
    },
}))(Tooltip);

export const DragAndDropWorkout = ({
    sectionsAndExercises,
    setSectionsAndExercises,
    setExercise,
    displayNote,
    originalExercises,
    profileId,
    openedNotes,
    setOpenedNotes,
    exercisesList,
}) => {
    const [deleteModal, setDeleteModal] = useState(false);
    const [current, setCurrent] = useState(null);
    const [opened, setOpened] = useState([]);
    const measurement_system = useSelector(state => state.appData.account.measurement_system);
    const [afterDelete, setAfterDelete] = useState(false)

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const handleDeleteElement = index => {
        setSectionsAndExercises(prev => {
            let arr = [...prev];
            arr.splice(index, 1);
            return arr;
        });
        setAfterDelete(true)
        setDeleteModal(false);
        setTimeout(() => {
            setAfterDelete(false)
        }, 1000)
    };

    useEffect(() => {
        if (afterDelete) {
            const res = putNumbersFunc(sectionsAndExercises)
            setSectionsAndExercises(res)
        }

    },[afterDelete])

    const onDragEnd = result => {
        if (!result.destination) return;
        const itemsReordered = reorder(
            sectionsAndExercises,
            result.source.index,
            result.destination.index
        );
        const res = putNumbersFunc([...itemsReordered])
        setSectionsAndExercises(res)
    };

    const handleAddExercise = (val, index) => {
        
        fetchExercise(val.id).then(res => {
            setExercise(res.data);
            return res.data
        }).then((res) => {
      const exersize = res.data
        if (profileId) {
            getPreviousExercise(profileId, exersize.id)
                .then(exerciseResponse => {
                    getNotesV2(profileId, { exercise_id: exersize.id })
                        .then(noteResponse => {
                            let note = noteResponse.data?.data?.length > 0 ? noteResponse.data.data[0] : null;

                            let array = JSON.parse(JSON.stringify(sectionsAndExercises));
                            const arr = putNumbersFunc(array)
                            arr[index] = {
                                variables: [...exersize.variables],
                                id: exersize.id + '',
                                number: arr[index]?.number,
                                type: 'exercise',
                                title: exersize.title,
                                note: note ? { id: note.id, note_text: note.note_text } : null,
                                sets: [
                                    {
                                        reps: 0,
                                        weight: 0,
                                        weight_unit: measurement_system === 'metric' ? 'kg' : 'lbs',
                                        distance: 0,
                                        distance_unit: measurement_system === 'metric' ? 'km' : 'yd',
                                        time: '00:00:00'
                                    }
                                ]
                            };

                            if (exerciseResponse.data.sets.length) {
                                arr[index].sets = exerciseResponse.data.sets.map(elem => {
                                    return {
                                        reps: elem.reps || 0,
                                        weight: elem.weight || 0,
                                        weight_unit: elem.weight_unit || measurement_system === 'metric' ? 'kg' : 'lbs',
                                        distance: elem.distance || 0,
                                        distance_unit: elem.distance_unit || measurement_system === 'metric' ? 'km' : 'yd',
                                        time: elem.time || '00:00:00'
                                    }
                                })
                            };
                            const result = putNumbersFunc(arr)
                            setSectionsAndExercises(result);
                        })
                })
        } else {
            let array = JSON.parse(JSON.stringify(sectionsAndExercises));
            const arr = putNumbersFunc(array)
            // let arr = JSON.parse(JSON.stringify(sectionsAndExercises));
            let arr2 = JSON.parse(JSON.stringify(originalExercises));

            arr[index] = {
                variables: [...exersize.variables],
                id: exersize.id + '',
                number: arr[index]?.number,
                type: 'exercise',
                title: exersize.title,
                sets: [
                    {
                        reps: 0,
                        weight: 0,
                        weight_unit: measurement_system === 'metric' ? 'kg' : 'lbs',
                        distance: 0,
                        distance_unit: measurement_system === 'metric' ? 'km' : 'yd',
                        time: '00:00:00'
                    }
                ]
            };

            const exerciseIndex = sectionsAndExercises.findIndex(elem => elem.id === sectionsAndExercises[index].id)

            arr2[exerciseIndex] = {
                variables: [...exersize.variables],
                id: exersize.id + '',
                type: 'exercise',
                title: exersize.title,
                sets: [
                    {
                        reps: 0,
                        weight: 0,
                        weight_unit: measurement_system === 'metric' ? 'kg' : 'lbs',
                        distance: 0,
                        distance_unit: measurement_system === 'metric' ? 'km' : 'yd',
                        time: '00:00:00'
                    }
                ]
            };

            setSectionsAndExercises(arr);
            }
        })
    };

    const getReps = (elem) => {
        if (!elem.sets.length) return '';
       
        // const lastAvailableSet = elem.sets[elem.sets.length - 1];
                // let result = lastAvailableSet ? lastAvailableSet.reps : ''
        return Number(elem.sets.reduce((acc, set) => {
            acc = acc + Number(set.reps);
            return acc;
        }, 0) / elem.sets.length).toFixed()
    };

    const getLastSetReps = elem => {
        if (!elem.sets.length) return '';
         const lastAvailableSet = elem.sets[elem.sets.length - 1];
        return lastAvailableSet ? lastAvailableSet.reps : ''
    }

    const getWeight = (elem) => {
        if (!elem.sets.length) return '';
        const averageWeight = Number(elem.sets.reduce((acc, set) => {
            acc = acc + Number(set.weight);
            return acc;
        }, 0) / elem.sets.length)
        return averageWeight ? averageWeight : ''
    };

    const getLastSetWeight = elem => {
        if (!elem.sets.length) return 0;
        const lastAvailableSet = elem.sets[elem.sets.length - 1];
        return lastAvailableSet ? lastAvailableSet.weight : 0
    }

    const getDistance = (elem) => {
        if (!elem.sets.length) return '';
        const averageDistance = Number(elem.sets.reduce((acc, set) => {
            acc = acc + Number(set.distance);
            return acc;
        }, 0) / elem.sets.length)
        return averageDistance ? averageDistance : ''
    };

    const getLastSetDistance = elem => {
        if (!elem.sets.length) return '';
        const lastAvailableSet = elem.sets[elem.sets.length - 1];
        return lastAvailableSet ? lastAvailableSet.distance : '';
    }

    const timeToSeconds = (timeStr) => {
        // Convert time in HH:MM:SS format to total seconds
        const [hours, minutes, seconds] = timeStr.split(":").map(Number);
        return hours * 3600 + minutes * 60 + seconds;
    }
      
    const secondsToTime = (totalSeconds) => {
        // Convert total seconds back to HH:MM:SS format
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;
        return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
    }

    const getTime = (elem) => {
        if (!elem.sets.length) return '00:00:00';

        const totalSeconds = elem.sets.map(set => set.time)
        .map(timeToSeconds)
        .reduce((sum, seconds) => sum + seconds, 0);
        const avgSeconds = Math.floor(totalSeconds / elem.sets.length);
        return secondsToTime(avgSeconds);
    };

    const getLastSetTime = elem => {
        if (!elem.sets.length) return '00:00:00';
        const lastSet = elem.sets[elem.sets.length - 1];

        if (lastSet) {
            return lastSet.time;
        }
    }

    const handleAddSet = (index) => {
        setSectionsAndExercises(prev => {
            const sectionsAndExercisesArray = JSON.parse(JSON.stringify(prev));
            sectionsAndExercisesArray[index].sets.push({
                reps: getLastSetReps(sectionsAndExercisesArray[index]),
                weight: getLastSetWeight(sectionsAndExercisesArray[index]),
                weight_unit: measurement_system === 'metric' ? 'kg' : 'lbs',
                distance: getLastSetDistance(sectionsAndExercisesArray[index]),
                distance_unit: measurement_system === 'metric' ? 'km' : 'yd',
                time: getLastSetTime(sectionsAndExercisesArray[index]),
            })
            return sectionsAndExercisesArray;
        })
    }

    const handleDeleteSet = (section_index, set_index) => {
        setSectionsAndExercises(prev => {
            let sectionsAndExercisesArray = JSON.parse(JSON.stringify(prev));
            const sets = sectionsAndExercisesArray[section_index].sets;
            sectionsAndExercisesArray[section_index].sets = sets.filter((_, idx) => set_index !== idx);
            return sectionsAndExercisesArray;
        })
    }

    const [setsLength, setSetsLength] = useState({});

    const handleChangeSets = (e, index, val) => {
        e.persist();
        setSectionsAndExercises(prev => {
            let value = e.target.value;
            if (val) value = val;
            let sectionsAndExercisesArray = JSON.parse(JSON.stringify(prev));
            let length = sectionsAndExercisesArray[index].sets.length;
            if (+value > 25) {
                setSetsLength({...setsLength, [index]: 25});
                value = 25
            };
            if (!value || +value === 0) {
                sectionsAndExercisesArray[index].sets.length = 1;
                setSetsLength({...setsLength, [index]: ''});
                return sectionsAndExercisesArray;
            }
            if (+value <= length) {
                setSetsLength({...setsLength, [index]: +value});
                sectionsAndExercisesArray[index].sets.length = +value;
            } else {
                for (let i = 0; i < +value - length; i++) {
                    sectionsAndExercisesArray[index].sets.push({
                        reps: getReps(sectionsAndExercisesArray[index]),
                        weight: getWeight(sectionsAndExercisesArray[index]),
                        weight_unit: measurement_system === 'metric' ? 'kg' : 'lbs',
                        distance: getDistance(sectionsAndExercisesArray[index]),
                        distance_unit: measurement_system === 'metric' ? 'km' : 'yd',
                        time: getTime(sectionsAndExercisesArray[index]),
                    })
                }
                setSetsLength({...setsLength, [index]: sectionsAndExercisesArray[index].sets.length});
            }
            return sectionsAndExercisesArray;
        })
    };

    const handleChangeReps = (e, index) => {
        e.persist();

        let value = e.target.value;
        if ((value + '').split('.')[1]?.length > 1) return;
        if (+value > 100) value = 100;
        if (+value < 0) value = 0;

        setSectionsAndExercises(prev => {
            const data = [...prev];
            data[index].sets = prev[index].sets.map(elem => ({
                ...elem,
                reps: value,
            }))
            return data;
        })
    };

    const handleChangeWeight = (e, index) => {
        e.persist();

        let value = e.target.value;

        if ((value + '').split('.')[1]?.length > 2) return;
            if (+value > 5000) value = '5000.00';
            if (+value < 0) value = '0.00';
    
            setSectionsAndExercises(prev => {
                const data = [...prev]
                data[index].sets = prev[index].sets.map(elem => ({
                    ...elem,
                    weight: value,
                }))
                return data;
            })
    };

    const handleWeightBlur = (e, index) => {
        e.persist();
        
        if (!e.target.value && e.target.value === '') {
            setSectionsAndExercises(prev => {
                const data = [...prev]
                data[index].sets = prev[index].sets.map(elem => ({
                    ...elem,
                    weight: '0.00',
                }))
                return data;
            })
        }
    }

    const handleChangeDistance = (e, index) => {
        e.persist();

        let value = e.target.value;
        if ((value + '').split('.')[1]?.length > 1) return;
        if (+value > 100) value = 100;
        if (+value < 0) value = 0;

        setSectionsAndExercises(prev => {
            const data = [...prev]
            data[index].sets = prev[index].sets.map(elem => ({
                ...elem,
                distance: value,
            }))
            return data;
        })
    };

    const handleChangeTime = (value, index) => {
        let val = value;
        if (+val.split(':')[0] > 24) val = '24:00:00';
        setSectionsAndExercises(prev => {
            const data = [...prev]
            data[index].sets = prev[index].sets.map(elem => ({
                ...elem,
                time: val,
            }))
            return data;
        })
    };

    const handleChangeCompleteStatus = (value, exerciseIndex, setIndex) => {
        setSectionsAndExercises(prev => {
            const data = [...prev]
            const setToUpdate = prev[exerciseIndex].sets[setIndex];
            data[exerciseIndex].sets[setIndex] = {...setToUpdate, completed: value };
            return data;
        })
    }

    const handleOpen = (id) => {
        setOpened(prev => {
            const arr = [...prev];
            const index = prev.findIndex(elem => elem === id);
            if (index === -1) {
                arr.push(id);
            } else {
                arr.splice(index, 1);
            }
            return arr;
        })
    };

    const handleOpenNotes = (id) => {
        setOpenedNotes(prev => {
            const arr = [...prev];
            const index = prev.findIndex(elem => elem === id);
            if (index === -1) {
                arr.push(id);
            } else {
                arr.splice(index, 1);
            }
            return arr;
        })
    };

    const handleChangeNote = (value, index) => {
        setSectionsAndExercises(prev => {
            let arr = JSON.parse(JSON.stringify(prev))
            arr[index].note = { note_text: value, id: null };
            return arr;
        })
    };

    return (
        <>
            <ConfirmModal
                modal={deleteModal}
                setModal={setDeleteModal}
                handleConfirm={() => handleDeleteElement(current)}
                title="Are you sure you want to delete?"
                submitText="Yes"
            />

            <div className='workout-dnd'>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {sectionsAndExercises?.map((sectionItem, sectionIndex) => {
                                    const variables = sectionItem?.variables?.reduce((acc, current) => {
                                        return {
                                            ...acc,
                                            [current]: true,
                                        }
                                    }, {});

                                    return (
                                        <Draggable key={sectionItem.id + '-' + sectionIndex} draggableId={sectionItem.id + '-' + sectionIndex} index={sectionIndex}>
                                            {(provided, snapshot) => {
                                                return (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <div
                                                            className='workout-dnd__draggble'
                                                            style={{ border: snapshot.isDragging ? '3px solid #1E1E2D' : '' }}
                                                        >
                                                            {sectionItem.type === 'section' && <div className='d-flex'>
                                                                <div className="create-workout__textfield">
                                                                    <TextField
                                                                        name="section_title"
                                                                        variant="outlined"
                                                                        type="text"
                                                                        label='Section Name'
                                                                        inputProps={{ maxLength: 100 }}
                                                                        style={{ width: '100%' }}
                                                                        value={sectionItem.title}
                                                                        error={Boolean(sectionItem.hasError && sectionItem.touched)}
                                                                        helperText={sectionItem.hasError && sectionItem.touched && sectionItem.errorText}
                                                                        onBlur={() => validateSectionTitle(sectionIndex, sectionsAndExercises, setSectionsAndExercises)}
                                                                        onChange={(e) => handleChangeSectionTitle(e.target.value, sectionIndex, setSectionsAndExercises)}
                                                                    />
                                                                </div>

                                                                <img
                                                                    src={trash}
                                                                    className='workout-dnd__delete'
                                                                    alt='delete'
                                                                    onClick={() => {
                                                                        setCurrent(sectionIndex)
                                                                        setDeleteModal(true);
                                                                    }}
                                                                />
                                                            </div>}

                                                            {sectionItem.type === 'exercise' && sectionItem.variables && (
                                                                <div className='workout-dnd__exercise'>
                                                                    <div className='workout-dnd__exercise-header'>
                                                                        <div style={{display: 'flex'}}>
                                                                    <div className='workout-dnd__exercise-number'>{sectionItem.number}.</div>
                                                                        <div className='d-flex align-items-center'>
                                                                            <div className='d-flex' style={{ maxWidth: '85%' }}>
                                                                                <div
                                                                                    className='workout-dnd__exercise-title pointer'
                                                                                    onClick={() => setExercise(sectionItem)}
                                                                                >{sectionItem.title}</div>
                                                                            </div>

                                                                            {displayNote && <StyledToolTip title="Add Note" placement="bottom">
                                                                                <div
                                                                                    style={{ marginLeft: 15 }}
                                                                                    className='workout-dnd__exercise-notes-button'
                                                                                    onClick={() => handleOpenNotes(sectionIndex)}
                                                                                >
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 448 512">
                                                                                        <path
                                                                                            fill={sectionItem.note?.note_text ? '#25436B' : '#4D4D4D'}
                                                                                            d="M312 320h136V56c0-13.3-10.7-24-24-24H24C10.7 32 0 42.7 0 56v400c0 13.3 10.7 24 24 24h264V344c0-13.2 10.8-24 24-24zm129 55l-98 98c-4.5 4.5-10.6 7-17 7h-6V352h128v6.1c0 6.3-2.5 12.4-7 16.9z">
                                                                                        </path>
                                                                                    </svg>
                                                                                </div>
                                                                            </StyledToolTip>}

                                                                            <img
                                                                                style={{ marginLeft: 10 }}
                                                                                src={trash}
                                                                                className='workout-dnd__exercise-delete'
                                                                                alt='delete'
                                                                                onClick={() => handleDeleteElement(sectionIndex)}
                                                                            />
                                                                            </div>
                                                                            </div>

                                                                        <div className='workout-dnd__exercise-header-actions'>
                                                                            <div className='workout-dnd__exercise-sets-number'>
                                                                                <div>Sets</div>
                                                                                <input
                                                                                    name="sets"
                                                                                    variant="outlined"
                                                                                    type="number"
                                                                                    min={0}
                                                                                    max={99}
                                                                                    maxLength={3}
                                                                                    style={{ width: '100%' }}
                                                                                    value={setsLength[sectionIndex] === '' ? setsLength[sectionIndex] : sectionsAndExercises[sectionIndex]?.sets.length}
                                                                                    onChange={(e) => handleChangeSets(e, sectionIndex)}
                                                                                    onBlur={setsLength[sectionIndex] === ''
                                                                                        ? (e) => handleChangeSets(e, sectionIndex, 1)
                                                                                        : () => {}
                                                                                    }
                                                                                    step={1}
                                                                                    onKeyPress={(e) => {
                                                                                        if ((+e.which !== 8 && +e.which !== 0 && +e.which < 48) || +e.which > 57) {
                                                                                            e.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            {variables?.reps && (
                                                                                <div className='workout-dnd__exercise-sets-number'>
                                                                                    <div>Reps</div>
                                                                                    <input
                                                                                        placeholder='0'
                                                                                        name="reps"
                                                                                        variant="outlined"
                                                                                        type="number"
                                                                                        style={{ width: '100%' }}
                                                                                        value={getReps(sectionItem)}
                                                                                        onChange={(e) => handleChangeReps(e, sectionIndex)}
                                                                                        min={0}
                                                                                        max={999}
                                                                                        maxLength={3}
                                                                                        step={1}
                                                                                        onKeyPress={(e) => {
                                                                                            if ((+e.which !== 8 && +e.which !== 0 && +e.which < 48) || +e.which > 57) {
                                                                                                e.preventDefault();
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                            {variables?.weight && (
                                                                                <div className='workout-dnd__exercise-sets-number'>
                                                                                    <div>Weight</div>
                                                                                    <input
                                                                                        placeholder='0.00'
                                                                                        name="weight"
                                                                                        variant="outlined"
                                                                                        type="number"
                                                                                        min={0.00}
                                                                                        max={999.00}
                                                                                        // maxLength={3}
                                                                                        style={{ width: '100%' }}
                                                                                        value={getWeight(sectionItem)}
                                                                                        onBlur={e => handleWeightBlur(e, sectionIndex)}
                                                                                        onChange={(e) => handleChangeWeight(e, sectionIndex)}
                                                                                        step={'any'}
                                                                                    />
                                                                                </div>
                                                                            )}

                                                                            {variables?.distance && (
                                                                                <div className='workout-dnd__exercise-sets-number'>
                                                                                    <div>Distance</div>
                                                                                    <input
                                                                                        placeholder='0'
                                                                                        name="distance"
                                                                                        variant="outlined"
                                                                                        type="number"
                                                                                        min={0.00}
                                                                                        max={999.00}
                                                                                        maxLength={3}
                                                                                        style={{ width: '100%' }}
                                                                                        value={getDistance(sectionItem)}
                                                                                        onChange={(e) => handleChangeDistance(e, sectionIndex)}
                                                                                        step={1}
                                                                                    />
                                                                                </div>
                                                                            )}

                                                                            {variables?.time && (
                                                                                <div className='workout-dnd__exercise-sets-number workout-dnd__time-input'>
                                                                                    <div>Time</div>
                                                                                    <CustomTimeInput
                                                                                        value={getTime(sectionItem)}
                                                                                        onChange={(value) => handleChangeTime(value, sectionIndex)}
                                                                                        className={`workout-dnd__time-input`}
                                                                                        inputProps={{
                                                                                            min: '00:00:00',
                                                                                            max: '20:00:00'
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            )}

                                                                            <div
                                                                                className={`workout-dnd__exercise-sets-arrow ${opened.findIndex(elem => elem === sectionIndex) !== -1 ? 'workout-dnd__exercise-sets-arrow_active' : ''}`}
                                                                                onClick={() => {
                                                                                    handleOpen(sectionIndex);

                                                                                    // hide both sections
                                                                                    if (
                                                                                        opened.findIndex(elem => elem === sectionIndex) !== -1 &&
                                                                                        openedNotes.findIndex(elem => elem === sectionIndex) !== -1
                                                                                    ) {
                                                                                        handleOpenNotes(sectionIndex);
                                                                                    }

                                                                                    // hide one section
                                                                                    if (opened.findIndex(elem => elem === sectionIndex) !== -1) {
                                                                                        return;
                                                                                    }

                                                                                    // open first time when note exists
                                                                                    if (
                                                                                        sectionItem.note?.note_text &&
                                                                                        openedNotes.findIndex(elem => elem === sectionIndex) === -1
                                                                                    ) {
                                                                                        handleOpenNotes(sectionIndex);
                                                                                    }
                                                                                }}
                                                                            >{'>'}</div>                                                                             
                                                                        </div>
                                                                    </div>

                                                                    {openedNotes.findIndex(elem => elem === sectionIndex) !== -1 &&
                                                                        <div className='workout-dnd__exercise-header-note'>
                                                                            <TextField
                                                                                inputProps={{ maxLength: 1000 }}
                                                                                multiline
                                                                                rows={3}
                                                                                name='note'
                                                                                placeholder='Note'
                                                                                variant='outlined'
                                                                                onChange={(e) => handleChangeNote(e.target.value, sectionIndex)}
                                                                                value={sectionItem.note?.note_text || ''}
                                                                                style={{ width: '100%' }}
                                                                            />
                                                                        </div>
                                                                    }

                                                                    {opened.findIndex(elem => elem === sectionIndex) !== -1 &&
                                                                        <ExerciseComponent
                                                                            exerciseItem={sectionItem}
                                                                            sectionsAndExercises={sectionsAndExercises}
                                                                            setSectionsAndExercises={setSectionsAndExercises}
                                                                            exerciseIndex={sectionIndex}
                                                                            handleDeleteSet={handleDeleteSet}
                                                                            handleChangeCompleteStatus={handleChangeCompleteStatus}
                                                                        />                                               
                                                                    }
                                                                    <button type='button' onClick={() => handleAddSet(sectionIndex)} className='exercise-add-set_btn'>
                                                                        <Add />
                                                                        Add Set
                                                                    </button>
                                                                </div>
                                                            )}

                                                            {sectionItem.type === 'exercise' && !sectionItem.variables && (
                                                                <div className='create-workout__dropdown'>
                                                                     <div className='workout-dnd__exercise-number'>{sectionItem.number}.</div>
                                                                    <Dropdown
                                                                        options={exercisesList}
                                                                        setFormData={(val) => {
                                                                            handleAddExercise(val, sectionIndex);
                                                                            setExercise(val);
                                                                            if (val) {
                                                                                setSectionsAndExercises(prev => {
                                                                                    let arr = [...prev];
                                                                                    arr[sectionIndex].exerciseError = '';
                                                                                    return arr;
                                                                                })
                                                                            }
                                                                        }}
                                                                        value={null}
                                                                        label="-- Add New Exercise --"
                                                                        width="80%"
                                                                        style={{ paddingTop: 15 }}
                                                                        disableClearable={true}
                                                                        error={!!sectionItem.exerciseError}
                                                                        helperText={!!sectionItem.exerciseError && sectionItem.exerciseError}
                                                                        blurOnSelect={true}
                                                                        clearOnBlur={true}
                                                                        clearOnEscape={true}
                                                                        handleClearValue={true}
                                                                    />
                                                                    <img
                                                                        src={trash}
                                                                        className='workout-dnd__delete'
                                                                        alt='delete'
                                                                        onClick={() => {
                                                                            handleDeleteElement(sectionIndex);
                                                                        }}
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                )
                                            }}
                                        </Draggable>
                                    )
                                })}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        </>
    )
};
