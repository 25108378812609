import React, { useEffect, useState } from "react";
import { Tabs, Tab, TextField } from "@material-ui/core";
import { TasksTable } from "./TasksTable/Table";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import Loader from "../../../components/Loader";
import ConfirmModal from "../../../components/ConfirmationModal";
import { TaskModal } from "./TaskModal/TaskModal";
import Dropdown from "../../../components/DropdownMultiselectv2";
import Dropdown2 from "../../../components/DropdownComponent";
import DatePicker from "../../../components/DatePicker";
import Pagination from "../../../components/Pagination";
import catchErrorMessage from "../../../helpers/errorCatcher";
import * as AlertState from "../../../store/ducks/auth.duck";
import {
  getTasksList,
  getProfileTasksList,
} from "../../../crud/dashboard.crud";
import { completeTasks, deleteAllTask } from "../../../crud/dashboard.crud";
import {
  getGroupsByAccountId,
  getUsersV3,
  getOwnGroups,
} from "../../../crud/info.crud";
import { useIsMount } from "../../../hooks/useIsMount";
import moment from "moment";
import { setTasksCount } from "../../../store/actions";
import "./task-list.scss";

export const TaskList = ({ profileId }) => {
  const currentMonth = new Date().getMonth();
  const nextmonthfirstday = new Date(
    new Date().getFullYear(),
    currentMonth + 1,
    1
  );
  const oneDay = 1 * 24 * 3600 * 1000;
  let now = new Date();
  const lasttime = moment().add(1, "Y");

  const dispatch = useDispatch();
  const [tab, setTab] = useState(0);
  const [data, setData] = useState([]);
  const [sort, setSort] = useState([]);
  const [check, setCheck] = useState([]);
  const [taskModal, setTaskModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [status, setStatus] = useState(0);
  const [search, setSearch] = useState("");
  const [types, setTypes] = useState([]);
  const [startDate, setStartDate] = useState(
    moment("09/01/2022").format("MM/DD/YYYY") + " 00:00:00"
  );
  const [endDate, setEndDate] = useState(
    moment(lasttime).format("MM/DD/YYYY") + " 23:59:59"
  );
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(false);
  const [lastPage, setLastPage] = useState(1);
  const [pagination, setPagination] = useState(1);
  const [isEdit, setIsEdit] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState();
  const [users, setUsers] = useState([]);
  const accountId = useSelector((state) => state.appData.account.id);
  const role = useSelector((state) => state.auth.user.roles);
  const plan = useSelector((state) => state.user_info.subscription?.plan?.name);
  const userId = useSelector((state) => state.auth?.user?.id);
  const isFirstRender = useIsMount();
  const [isGroupLoading, setIsGroupLoading] = useState(true);
  const [isUsersLoading, setIsUsersLoading] = useState(true);

  useEffect(() => {
    if (role === "staff") setIsUsersLoading(false);
  }, [role]);

  useEffect(() => {
    // Fetch groups
    if (!accountId || !role) return;

    if (role !== "admin" && role !== "super-admin" && role !== "supervisor") {
      setIsGroupLoading(false);
      return;
    }

    if (role === "supervisor") {
      getOwnGroups(userId)
        .then((res) => {
          if (res.data.list.length > 0) {
            setGroups(
              res.data.list.map((elem) => {
                return {
                  ...elem,
                  title: `${elem.name}`,
                };
              })
            );
            setSelectedGroup({
              ...res.data.list[0],
              title: `${res.data.list[0].name}`,
            });
          }
        })
        .finally(() => setIsGroupLoading(false));
      return;
    }

    getGroupsByAccountId(accountId)
      .then((res) => {
        setGroups(
          res.data.list.map((elem) => {
            return {
              ...elem,
              title: `${elem.name}`,
            };
          })
        );
        setSelectedGroup({
          ...res.data.list[0],
          title: `${res.data.list[0]?.name}`,
        });
      })
      .finally(() => setIsGroupLoading(false));
  }, [role, accountId]);

  useEffect(() => {
    // Fetch users

    if (role && role !== "admin") {
      if (isFirstRender) return;
    }

    getUsersV3({ group_id: selectedGroup?.id || null, limit: 500 })
      .then((res) => {
        const list = res.data?.list.map((elem) => ({
          ...elem,
          title: `${elem.first_name} ${elem.last_name}`,
        }));
        const sorted = list.sort((a, b) => {
          let first = a.title.toLowerCase();
          let second = b.title.toLowerCase();
          if (first < second) {
            return -1;
          }
          if (first > second) {
            return 1;
          }
          return 0;
        });
        setUsers(sorted);
      })
      .catch((err) => {
        let errText = catchErrorMessage(err);
        dispatch(
          AlertState.actions.alert({
            text: errText,
            variant: false,
          })
        );
      })
      .finally(() => setIsUsersLoading(false));
  }, [selectedGroup, role]);

  const loadTasks = () => {
    setLoader(true);
    let usersIds = [];

    if (selectedGroup && users.length < 1) {
      setLoader(false);
      setData([]);
      return;
    }

    if (selectedUsers?.length > 0) {
      usersIds = selectedUsers.map((elem) => elem.id);
    }

    if (selectedGroup && users.length > 0 && selectedUsers?.length < 1) {
      usersIds = users.map((elem) => elem.id);
    }

    let start_at = moment(startDate).format("MM/DD/YYYY") + " 00:00:00";
    const start = moment(start_at)
      .utc()
      .format("MM/DD/YYYY HH:mm:ss");
    let end_at = moment(endDate).format("MM/DD/YYYY") + " 23:59:59";
    const end = moment(end_at)
      .utc()
      .format("MM/DD/YYYY HH:mm:ss");

    if (profileId) {
      getProfileTasksList(profileId, {
        page: pagination,
        limit,
        search,
        date_from: start,
        date_to: end,
        is_completed: status,
        sort_field: sort[0],
        sort_direction: sort[1],
        user_ids: usersIds,
        types: types.map((elem) => elem.value),
      })
        .then((res) => {
          setData(res.data.list);
          setPage({
            ...res.data.pagination,
            per_page: res.data.pagination.limit,
          });
          setLastPage(res.data.pagination.last_page);
          setLoader(false);
          dispatch(setTasksCount(res.data.pagination.total));
        })
        .catch(() => setLoader(false));
    } else {
      getTasksList(accountId, {
        page: pagination,
        limit,
        search,
        date_from: start,
        date_to: end,
        is_completed: status,
        sort_field: sort[0],
        sort_direction: sort[1],
        user_ids: usersIds,
        types: types.map((elem) => elem.value),
      })
        .then((res) => {
          setData(res.data.list);
          setPage({
            ...res.data.pagination,
            per_page: res.data.pagination.limit,
          });
          setLastPage(res.data.pagination.last_page);
          setLoader(false);
        })
        .catch(() => setLoader(false));
    }
  };

  useEffect(() => {
    // Fetch tasks

    if (role && role !== "client") {
      if (isFirstRender || isGroupLoading || isUsersLoading) return;
    }

    if (!profileId && !accountId) return;
    loadTasks();
  }, [
    accountId,
    tab,
    selectedUsers,
    selectedGroup,
    startDate,
    endDate,
    sort,
    pagination,
    limit,
    types,
    users,
    isGroupLoading,
    isUsersLoading,
  ]);

  useEffect(() => {
    // Fetch tasks when search
    if (isFirstRender || isGroupLoading || isUsersLoading) return;
    if (!profileId && !accountId) return;
    const timer = setTimeout(() => {
      setLoader(true);
      const usersId = selectedUsers.map((elem) => elem.id);
      setPage(1);

      if (profileId) {
        getProfileTasksList(profileId, {
          page: 1,
          limit,
          search,
          date_from: startDate,
          date_to: endDate,
          is_completed: status,
          sort_field: sort[0],
          sort_direction: sort[1],
          user_ids: usersId,
          types: types.map((elem) => elem.value),
        })
          .then((res) => {
            setData(res.data.list);
            setPage({
              ...res.data.pagination,
              per_page: res.data.pagination.limit,
            });
            setLastPage(res.data.pagination.last_page);
            setLoader(false);
          })
          .catch(() => setLoader(false));
      } else {
        getTasksList(accountId, {
          page: 1,
          limit,
          search,
          date_from: startDate,
          date_to: endDate,
          is_completed: status,
          sort_field: sort[0],
          sort_direction: sort[1],
          user_ids: usersId,
          types: types.map((elem) => elem.value),
        })
          .then((res) => {
            setData(res.data.list);
            setPage({
              ...res.data.pagination,
              per_page: res.data.pagination.limit,
            });
            setLastPage(res.data.pagination.last_page);
            setLoader(false);
          })
          .catch(() => setLoader(false));
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [search]);

  const handleComplete = () => {
    setLoader(true);
    completeTasks(accountId, check)
      .then(() => {
        dispatch(
          AlertState.actions.alert({
            text: "The task has been successfully completed",
            variant: true,
          })
        );
        loadTasks();
      })
      .catch((err) => {
        let errText = catchErrorMessage(err);
        dispatch(
          AlertState.actions.alert({
            text: errText,
            variant: false,
          })
        );
      })
      .finally(() => setLoader(false));
  };

  const deleteTask = () => {
    setLoader(true);
    deleteAllTask(accountId, check)
      .then(() => {
        setDeleteModal(false);
        setCheck([]);
        loadTasks();
        dispatch(
          AlertState.actions.alert({
            text: "Tasks are deleted",
            variant: true,
          })
        );
      })
      .catch((err) => {
        dispatch(
          AlertState.actions.alert({
            text: catchErrorMessage(err),
            variant: false,
          })
        );
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleChangeTypes = (value) => {
    setTypes(value);
  };

  const handleSelectGroup = (val) => {
    setIsUsersLoading(true);
    setSelectedGroup(val);
    setSelectedUsers([]);
  };

  const handleSelectUsers = (val) => {
    setSelectedUsers(val);
  };

  return (
    <div className="task-list-page">
      <ConfirmModal
        modal={deleteModal}
        setModal={setDeleteModal}
        handleConfirm={deleteTask}
        title={`Are you sure you want to delete tasks?`}
        submitText="Confirm"
      />

      <Loader visible={loader} />
      {taskModal && (
        <TaskModal
          modal={taskModal}
          setModal={setTaskModal}
          loadTasks={loadTasks}
          isEdit={isEdit}
          check={check}
          setIsEdit={setIsEdit}
        />
      )}

      <div className="header">
        <Tabs
          value={tab}
          onChange={(e, value) => setTab(value)}
          indicatorColor="primary"
          textColor="primary"
          scrollButtons="auto"
          variant="scrollable"
          style={{ marginBottom: 20 }}
        >
          <Tab
            label={"OPEN"}
            onClick={() => {
              setTab(0);
              setStatus(0);
            }}
          />
          <Tab
            label={"COMPLETED"}
            onClick={() => {
              setTab(1);
              setStatus(1);
            }}
          />
          <Tab
            label={"ALL"}
            onClick={() => {
              setTab(2);
              setStatus();
            }}
          />
        </Tabs>

        <Button
          variant="primary"
          className="btn-blue"
          onClick={() => setTaskModal(true)}
        >
          Create Task
        </Button>
      </div>

      <div className="task-list-page__sort-container">
        <div className="task-list-page__sort-buttons sort-buttons_first-block">
        <div className="task-list-page__sort-input">
            <TextField
              name="search"
              variant="outlined"
              type="text"
              label="Search"
              inputProps={{ maxLength: 255 }}
              value={search}
              onChange={handleSearch}
            />
          </div>
          <div className="task-list-page__sort-input" style={{ zIndex: 10 }}>
            <Dropdown
              label="Task Type"
              style={{ width: "100%", background: "#fff" }}
              onChange={handleChangeTypes}
              value={types}
              options={[
                { title: "General", value: "general" },
                { title: "Metrics", value: "complete_metric" },
                { title: "Forms", value: "complete_form" },
                { title: "Goals", value: "new_goal" },
              ]}
            />
          </div>

          {!profileId &&
            (role === "admin" ||
              role === "super-admin" ||
              role === "supervisor") &&
            plan !== "Gym" && (
              <div className="task-list-page__sort-input">
                <Dropdown2
                  options={groups}
                  value={selectedGroup}
                  label="Groups"
                  setFormData={handleSelectGroup}
                  getOptionSelected={(option, elem) => option.id === elem.id}
                  width={"150px"}
                />
              </div>
            )}

          {!profileId && role !== "staff" && (
            <div className="task-list-page__sort-input">
              <Dropdown
                style={{ width: "150px" }}
                onChange={handleSelectUsers}
                value={selectedUsers}
                options={users}
                label="All Users"
              />
            </div>
          )}

          <div
            className="task-list-page__sort-input date-picker-start"
            style={{ zIndex: 20 }}
          >
            <DatePicker
              name="date"
              label="Date From"
              future={true}
              format={"MM/DD/YYYY"}
              value={startDate}
              onChange={(value) => setStartDate(value)}
            />
          </div>
          <div className="task-list-page__sort-input" style={{ zIndex: 20 }}>
            <DatePicker
              name="date"
              label="Date To"
              future={true}
              format={"MM/DD/YYYY"}
              value={endDate}
              onChange={(value) => setEndDate(value)}
            />
          </div>
        </div>
      </div>
      <div className="task-list-page__eddited-buttons">
        {check.length > 0 && (
          <>
            <Button
              variant="secondary"
              onClick={handleComplete}
              className="task-list-page__filter-button"
            >
              Complete
            </Button>

            {check.length === 1 && (
              <>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setIsEdit(true);
                    setTaskModal(true);
                  }}
                  style={{ marginLeft: 15 }}
                  className="task-list-page__filter-button"
                >
                  Edit
                </Button>
              </>
            )}

            <Button
              variant="secondary"
              onClick={() => setDeleteModal(true)}
              style={{ marginLeft: 15 }}
              className="task-list-page__filter-button"
            >
              Delete
            </Button>
          </>
        )}
      </div>

      <TasksTable
        data={data}
        check={check}
        setCheck={setCheck}
        sort={sort}
        setSort={setSort}
      />

      <Pagination
        show={limit}
        setShow={setLimit}
        page={page}
        setPage={setPage}
        pagination={pagination}
        setPagination={setPagination}
        lastPage={lastPage}
        data={data}
        paginationSize="lg"
      />
    </div>
  );
};
