import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Event } from './Event';
import { useLocation } from 'react-router-dom';
import './calendar-component.scss';

const timeArray = ['12 am', '1 am', '2 am', '3 am', '4 am', '5 am', '6 am', '7 am', '8 am', '9 am', '10 am', '11 am', '12 pm', '1 pm', '2 pm', '3 pm', '4 pm', '5 pm', '6 pm', '7 pm', '8 pm', '9 pm', '10 pm', '11 pm'];
const daysArray = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const WeekCalendar = ({
    currentDate,
    events,
    setSelectedEventId,
    setCreateModal
}) => {
    const [eventsWithProps, setEventsWithProps] = useState([]);
    const location = useLocation();
    const isProfile = !!(location.pathname.split('/')[2]);

    useEffect(() => {
        if (!events.length) setEventsWithProps(events);
        let idCount = 1;
        const exclude = [];
        const result = events.map(elemA => {
            // if match was found before - add matchId to current event
            const excludedId = exclude.find(item => item.id === elemA.id)?.matchId;
            if (excludedId) {
                return {
                    ...elemA,
                    matchId: excludedId,
                }
            };

            const matches = events.filter(elemB => {
                if (elemA.id === elemB.id) return false;

                if (
                    moment(elemA.end_at).isBetween(
                        moment(elemB.start_at).subtract(1, 's'),
                        moment(elemB.end_at).add(1, 's')
                    ) ||
                    moment(elemA.start_at).isBetween(
                        moment(elemB.start_at).subtract(1, 's'),
                        moment(elemB.end_at).add(1, 's')
                    )
                ) {
                    exclude.push({
                        ...elemB,
                        matchId: idCount,
                    });
                    return true;
                } else return false;
            });

            // add matchId to current event
            if (matches.length > 0) {
                const matchId = idCount;
                idCount = idCount + 1;
                return {
                    ...elemA,
                    matchId: matchId,
                }
            }

            // if no matches
            return elemA;
        });

        setEventsWithProps(result);
    }, [events]);

    const getCellClassName = (index) => {
        const date1 = new Date(currentDate);
        const date2 = new Date(currentDate);
        const month = moment(currentDate).format('MMMM');
        const sunday = moment(date1.setDate(date1.getDate() - date1.getDay())).format('DD');
        const saturday = moment(date2.setDate(date2.getDate() + (6 - date2.getDay()))).format('DD');
        const year = moment(currentDate).format('YYYY');

        if (
            year !== moment().format('YYYY') ||
            month !== moment().format('MMMM') ||
            sunday !== moment(new Date().setDate(new Date().getDate() - new Date().getDay())).format('DD') ||
            saturday !== moment(new Date().setDate(new Date().getDate() + (6 - new Date().getDay()))).format('DD')
        ) return '';

        return +new Date().getDay() === +index ? 'current' : ''
    };

    const getColumnWidth = () => 100 / 8;

    const getEventWidth = () => 100 / 7;

    const getHeight = (event) => {
        const startAt = new Date(event.start_at);
        const endAt = new Date(event.end_at);
        const diff = endAt - startAt;
        var minutes = Math.floor(diff / 60000);
        return `${minutes}px`;
    };

    const getTop = (event) => {
        const hours = moment(event.start_at).format('HH');
        const minutes = moment(event.start_at).format('mm');
        let result = +hours * 60 + +minutes;
        return `${result}px`;
    };

    const getCuttedEventWidth = (event) => {
        if (event && event.matchId) {
            let eventsCount = eventsWithProps.filter(elem => elem.matchId === event.matchId).length;
            return getEventWidth() / eventsCount + '%';
        }
        return getEventWidth() + '%';
    };

    const getLeft = (event) => {
        const day = new Date(event.start_at).getDay();

        let index = 0;
        if (event.matchId) {
            let matches = eventsWithProps.filter(elem => elem.matchId === event.matchId);
            index = matches.findIndex(elem => elem.id === event.id);
        };

        const cuttedEventWidth = (event) => {
            if (event && event.matchId) {
                let eventsCount = eventsWithProps.filter(elem => elem.matchId === event.matchId).length;
                return getEventWidth() / eventsCount;
            }
            return getEventWidth();
        };

        if (window.innerWidth < 890) {
            return `calc(${day * 100 + 'px'} + ${index * cuttedEventWidth(event) + '%'})`;
        };

        return `calc(${day * getEventWidth() + '%'} + ${index * cuttedEventWidth(event) + '%'})`;
    };

    const getMinWidth = (event) => {
        if (event && event.matchId) {
            let eventsCount = eventsWithProps.filter(elem => elem.matchId === event.matchId).length;
            return 100 / eventsCount + 'px';
        }
        return '100px';
    };

    return (
        <div className={`new-calendar__table-container ${isProfile ? 'new-calendar__table-container-profile' : ''}`}>
          <table className="settingtable2">
            <thead>
              <tr>
                <th></th>
                {daysArray.map((item, index) => {
                  return (
                    <th key={index} className={`${getCellClassName(index)}`}>
                      {item}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {timeArray.map((item, index) => {
                return (
                  <tr>
                    <td key={index}>{item}</td>
                    {new Array(7).fill("").map((elem, index) => (
                      <td
                        key={index}
                        className={`${getCellClassName(index)}`}
                      ></td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div
                    className='calendar-body'
                    style={{
                        left: window.innerWidth < 890 ? '100px' : '',
                        width: `calc(100% - ${getColumnWidth() + '%'})`,
                    }}
                >
                    {eventsWithProps.map(event => {
                        return (
                            <div key={event.id}>
                                <Event
                                    item={event}
                                    getHeight={getHeight}
                                    getEventWidth={getCuttedEventWidth}
                                    getMinWidth={getMinWidth}
                                    getTop={getTop}
                                    getLeft={getLeft}
                                    onClick={() => {
                                        setSelectedEventId(event.id)
                                        setCreateModal(true)
                                    }}
                                />
                            </div>
                        )
                    })}
                </div>
        </div>
    )
};