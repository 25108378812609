import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import './report-details.scss';
import styled from 'styled-components';
import ArrowDown from '@material-ui/icons/ArrowDropDown';
import moment from 'moment';
import { Pagination } from 'react-bootstrap';
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  OutlinedInput,
} from '@material-ui/core';

export const ReportDetails = ({
  header,
  data,
  meta,
  setMeta,
  role,
  usersVis,
  groupsVis,
  setSort,
  sort,
  additionalData,
}) => {
  const inputLabel = useRef();
  const [labelWidth, setLabelWidth] = useState(0);
  const plan = useSelector((state) => state.user_info.subscription?.plan?.name);

  useEffect(() => {
    inputLabel.current && setLabelWidth(inputLabel.current.offsetWidth);
  }, [inputLabel]);

  const sortEdit = (item) => {
    setMeta({
      sort: item.sort_key ? item.sort_key : item.key,
      dir:
        (meta.sort === item.key || item.sort_key) && meta.dir === 'desc'
          ? 'asc'
          : 'desc',
    });
  };

  return (
    <ReportTableStyled className="settingtable">
      <table>
        <thead>
          <tr>
            {header?.map((item, index) => {
              if (item.label === 'Group' && plan === 'Gym') {
                return null;
              } else if (item.label === 'Group' && role === 'staff') {
                return null;
              } else if (item.label === 'Group' && !groupsVis) {
                return null;
              } else if (item.label === 'User' && role === 'staff') {
                return null;
              } else if (
                item.label === 'User' &&
                plan !== 'Gym' &&
                plan !== 'Enterprise'
              ) {
                return null;
              } else if (item.label === 'User' && !usersVis) {
                return null;
              } else
                return item.roleHide === role ? null : (
                  <td
                    key={index}
                    onClick={() => sortEdit(item)}
                    className={`${item.left ? 'left-content' : ''}${
                      meta.sort === item.value ? ' active' : ''
                    }`}
                    style={{ padding: '10px 5px' }}
                  >
                    <div
                      className="table-sort"
                      style={{
                        minWidth: '153px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                      }}
                    >
                      {item.label === 'Total :metric Change'
                        ? `Total ${additionalData?.metric_id?.metric} Change`
                        : item.label}

                      <ArrowDown
                        className={`sort-arrow${
                          meta.sort === item.key || item.sort_key
                            ? ' active'
                            : ''
                        }${
                          (meta.sort === item.key || item.sort_key) &&
                          meta.dir === 'desc'
                            ? ' reverse'
                            : ''
                        }`}
                      />
                    </div>
                  </td>
                );
            })}
          </tr>
        </thead>
        <tbody>
          {data?.map((item, index) => {
            return (
              <tr key={index}>
                {header?.map((elem) => {
                  if (elem.label === 'Group' && plan === 'Gym') {
                    return null;
                  } else if (elem.label === 'Group' && role === 'staff') {
                    return null;
                  } else if (elem.label === 'Group' && !groupsVis) {
                    return null;
                  } else if (elem.label === 'User' && role === 'staff') {
                    return null;
                  } else if (
                    elem.label === 'User' &&
                    plan !== 'Gym' &&
                    plan !== 'Enterprise'
                  ) {
                    return null;
                  } else if (elem.label === 'User' && !usersVis) {
                    return null;
                  } else            
                    return (
                      <td
                        className="left-content"
                        style={{
                          width: '131px',
                        }}
                      >
                        {item[elem.key] === NaN ||
                        item[elem.key] === null ||
                        item[elem.key] === ''
                          ? '--'
                          : elem.type === 'percent'
                          ? typeof item[elem.key] === 'number'
                            ? `${item[elem.key].toFixed(2)} %`
                            : item[elem.key]
                          : elem.type === 'date'
                          ? moment(item[elem.key]).format('YYYY-MM-DD')
                          : item[elem.key]}
                      </td>
                    );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>

      {data.length === 0 ? (
        <div
          style={{
            margin: '20px auto',
            textAlign: 'center',
          }}
        >
          No data for the selected parameters
        </div>
      ) : null}

      <div className="pagination-wrap">
        <Pagination size="lg">
          <Pagination.First
            onClick={() => setMeta({ ...meta, current_page: 1 })}
            disabled={meta.current_page === 1}
          />
          <Pagination.Prev
            onClick={() =>
              setMeta({ ...meta, current_page: meta.current_page - 1 })
            }
            disabled={meta.current_page === 1}
          />
          <Pagination.Item>{meta.current_page}</Pagination.Item>
          <Pagination.Next
            onClick={() =>
              setMeta({ ...meta, current_page: meta.current_page + 1 })
            }
            disabled={meta.current_page === meta.last_page}
          />
          <Pagination.Last
            onClick={() => setMeta({ ...meta, current_page: meta.last_page })}
            disabled={meta.current_page === meta.last_page}
          />
        </Pagination>
        <div className="pagination-show">
          <FormControl variant="outlined" className="formControl">
            <InputLabel ref={inputLabel}>Show Entries</InputLabel>
            <Select
              value={meta.per_page}
              input={<OutlinedInput labelWidth={labelWidth} />}
              onChange={(e) => {
                setMeta({ ...meta, per_page: e.target.value, current_page: 1 });
              }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </FormControl>
          <Typography variant="body1" gutterBottom>
            {meta.current_page &&
              `Showing
                                ${(meta.current_page - 1) * meta.per_page +
                                  (meta.total ? 1 : 0)} - 
                                ${
                                  meta.current_page * meta.per_page > meta.total
                                    ? meta.total
                                    : meta.current_page * meta.per_page
                                }
                            of ${meta.total}`}
          </Typography>
        </div>
      </div>
    </ReportTableStyled>
  );
};

const ReportTableStyled = styled.div`
  .table-sort {
    display: flex;
    align-items: center;
  }

  td.active {
    font-weight: 600;
  }

  .sort-arrow {
    opacity: 0.7;
    transition: all 0.3s ease-out;

    &.active {
      opacity: 1;
    }

    &.reverse {
      transform: rotate(180deg);
    }
  }

  table thead tr td.left-content {
    text-align: left;
  }

  table tbody tr td.left-content {
    text-align: left;
  }

  .profile-photo {
    height: 30px;
    width: 30px;
    object-fit: cover;
    border-radius: 15px;
    margin-right: 10px;
  }

  .pagination-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;

    .desktop-vis {
      display: none;

      @media screen and (min-width: 760px) {
        display: flex;
      }
    }

    .mobile-width-100p {
      @media screen and (max-width: 760px) {
        width: 100%;
      }
    }

    .mobile-vis {
      display: flex;

      @media screen and (min-width: 760px) {
        display: none;
      }
    }

    .header-list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    .MuiTab-wrapper {
      font-size: 12px;
      font-weight: 700;
    }

    .btn {
      margin-right: 15px;
    }

    .edit-all {
      height: 100%;
      border-radius: 4px;
      background-color: #eef1ff;
      padding: 0 12px;
      font-weight: 600;
      font-size: 13px;
      line-height: 19px;
      color: #5d78ff;
      display: flex;
      align-items: center;
    }

    .pagination {
      margin: 0;

      @media (max-width: 600px) {
        margin: 10px auto;
      }

      &-wrap {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 10px;

        .MuiSelect-select {
          padding: 7px;
        }
      }

      &-show {
        display: flex;
        align-items: center;
      }
    }

    .formControl {
      width: 150px;
      margin: 5px 10px;
    }
  }
`;
